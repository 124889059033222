import getVal from 'lodash/get';
import { getAmountQty } from '../../item';
import {
  formatUnit,
  getBonuses,
  getPrices,
  isNearly,
} from './methods-002';

const format = (item) => {
  const get = getAmountQty(item, 'get');
  const ret = getAmountQty(item, 'ret');
  const weight = getVal(item, 'weight', 0);
  const weightPrice = getVal(item, 'weightPrice', 0);
  const nearly = isNearly(weightPrice);
  const priceUnit = formatUnit(weightPrice);
  const weightUnit = formatUnit(weight);
  const photo = item.photo ? item.photo : '';
  const prices = getPrices(item);
  const bonuses = getBonuses(item);
  return {
    ...item,
    bonuses,
    get,
    ret,
    nearly,
    photo,
    prices,
    priceUnit,
    weightUnit,
    weight,
    weightPrice,
  };
};

export default format;
