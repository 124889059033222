import React from 'react';
import IconNew from '../New';
import IconBonus from '../Bonus';
import IconReturn from '../Return';
import '../style.scss';

const New = ({ item }) => {
  if (!item.isNew) return null;
  return (
    <li>
      <IconNew />
    </li>
  );
};

const Bonus = ({ item }) => {
  if (item.bonuses < 1) return null;
  return (
    <li>
      <IconBonus />
    </li>
  );
};

const Returns = ({ item }) => {
  if (item.returns < 1) return null;
  return (
    <li>
      <IconReturn />
    </li>
  );
};

const View = ({ item }) => (
  <div className="iconBar">
    <ul>
      <New item={item} />
      <Bonus item={item} />
      <Returns item={item} />
    </ul>
  </div>
);

export default View;
