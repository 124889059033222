import extend from 'lodash/fp/extend';
import pipe from 'lodash/fp/pipe';
import props from 'lodash/fp/props';
import zipObj from 'lodash/fp/zipObj';

const updatedAt = (features) => [...features, Date.now()];

export default (state, data) => pipe(
  props([
    'payload.bonuses',
    'payload.returns',
  ]),
  updatedAt,
  zipObj([
    'bonuses',
    'returns',
    'updatedAt',
  ]),
  (features) => ({ features }),
  extend({ ...state }),
)(data);
