import { mergeItems, prepare } from './methods-001';

export default (state, { payload }) => {
  const items = prepare(payload);
  const byID = mergeItems(state, items);
  const byCats = {
    ...state.byCats,
    ...items.byCats,
  };
  const allIDs = Object.keys(byID);
  return {
    ...state,
    allIDs,
    byCats,
    byID,
  };
};
