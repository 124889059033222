import lt from 'lodash/fp/lt';
import pipe from 'lodash/fp/pipe';
import prop from 'lodash/fp/prop';
import toInteger from 'lodash/fp/toInteger';

const toBool = (bool) => !!bool;

export const aboveZero = (path) => pipe(
  prop(path),
  toInteger,
  lt(0),
);

export const go = (smth) => () => smth;

export const isTrue = (path) => pipe(
  prop(path),
  toBool,
);
