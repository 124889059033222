import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form'
import ListGroup from 'react-bootstrap/ListGroup';
import IconSearch from '../icons/Search';
import ListItem from './List';

const Component = ({ isOpen, addresses, setTo, setClose, to }) => {
  const [addrs, setAddrs] = useState(addresses);
  const handleChange = (val) => {
    setAddrs(
      addresses.filter(({ address }) => address.toLowerCase().includes(val.toLowerCase()))
    );
  };
  if (!isOpen) return null;
  return (
    <Modal
      show={isOpen}
      onHide={setClose}
      centered
    >
      <Modal.Header className="show-grid p-1 pt-3">
        <Container>
          <Modal.Title>
            Выберите, пожалуйста, адрес доставки
          </Modal.Title>
        </Container>
      </Modal.Header>
      <Modal.Body className="show-grid p-1 mb-4">
        <Container>
          <Form.Group className="my-3">
            <Form.Control
              type="text"
              placeholder="Начните вводить адрес"
              onChange={(event) => handleChange(event.target.value)}
            />
            <IconSearch />
          </Form.Group>
          <ListGroup as="ul">
            {addrs.map((address) => (
              <ListItem
                address={address}
                key={`${address.id}`}
                setTo={setTo}
                to={to}
              />
            ))}
          </ListGroup>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default Component;
