import at from 'lodash/at';
import reduce from 'lodash/reduce';
import formatItems from '../../../../utils/format/items';
import { merge } from '../../../../utils/item';
import { emptyCat, formatCat, notFetched } from './methods-002';

export const mergeItems = (state, items) => reduce(items.byID, (out, nextItem, itemID) => {
  const [prevItem] = at(state, `byID.${itemID}`);
  const item = merge(prevItem, nextItem);
  return { ...out, [itemID]: item };
}, state.byID);

export const prepare = (payload) => {
  const [catID, items] = at(payload, ['catID', 'items']);
  if (notFetched(items)) return emptyCat(catID);
  const byCats = formatCat(catID, items);
  const byID = formatItems(items);
  return { byCats, byID };
};
