import React from 'react';

const Component = ({ item }) => (
  <div className="note">
    {item.weightUnit.weight}
    <small>
      {' '}
      {item.weightUnit.unit}
    </small>
  </div>
);

export default Component;
