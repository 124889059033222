import curry from 'lodash/fp/curry';
import pipe from 'lodash/fp/pipe';
import prop from 'lodash/fp/prop';
import { createSlice } from '@reduxjs/toolkit';
import fulfilled from './thunks/fetch/fulfilled';

const initialState = {
  features: {
    bonuses: false,
    returns: false,
  },
  updatedAt: 0,
};

const featuresSlice = createSlice({
  name: 'features',
  initialState,
  reducers: {},
  extraReducers: {
    'auth/exit/pending': () => initialState,
    'features/fetch/fulfilled': fulfilled,
  },
});

export const features = (state) => state.features;

const isFeatureActive = curry((featureName) => pipe(
  prop(`features.${featureName}`),
  (active) => !!active,
));

export const isActiveBonuses = isFeatureActive('bonuses');

export const isActiveReturns = isFeatureActive('returns');

export const featuresStatus = (state) => ({
  isActiveBonuses: isActiveBonuses(state),
  isActiveReturns: isActiveReturns(state),
});

export const nextScreenData = (state) => ({
  features: {
    isActiveBonuses: isFeatureActive('bonuses')(state),
    isActiveReturns: isFeatureActive('returns')(state),
  },
});

export default featuresSlice.reducer;
