import at from 'lodash/at';
import isNil from 'lodash/isNil';
import includes from 'lodash/fp/includes';
import map from 'lodash/fp/map';
import pipe from 'lodash/fp/pipe';
import props from 'lodash/fp/props';
import dayjs from 'dayjs';

const notValid = pipe(
  props(['date', 'from', 'to']),
  map(isNil),
  includes(true),
);

export const when = (shipment) => {
  if (notValid(shipment)) return null;
  const [
    shipmentDate,
    shipmentFrom,
    shipmentTo,
  ] = at(shipment, [
    'date',
    'from',
    'to',
  ]);
  const date = dayjs(shipmentDate).format('DD.MM.YYYY');
  const from = shipmentFrom.slice(0, 5);
  const to = shipmentTo.slice(0, 5);
  return { date, from, to };
};

export const where = (shipment) => {
  const [address] = at(shipment, ['address']);
  if (isNil(address)) return null;
  return { address };
};
