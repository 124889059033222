import React from 'react';
import eq from 'lodash/fp/eq';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap'
import IconInfo from '../../../icons/Info';
import { getDebts } from '../../../../reducers';

const View = () => {
  const { debt, debtStale } = useSelector(getDebts, eq);
  if (debt < 1) return null;
  return (
    <Row className="mb-4">
      <Col>
        <div className="informer rounded">
          <IconInfo />
          <div className="text">
            Задолженность {debt} руб.
          </div>
          {debtStale > 0 &&
            <div className="text">
              в т.ч. просроченная {debtStale} руб
            </div>
          }
        </div>
      </Col>
    </Row>
  );
};

export default View;
