import React from 'react';
import eq from 'lodash/fp/eq';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import Header from '../../../../components/Header';
import Stepper from '../../../../components/cart/Stepper';
import Totals from '../../../../components/cart/Totals';
import PageHeader from '../../../../components/PageHeader';
import Balance from '../../../../components/Bonus/Balance';
import CartCredit from '../../../../components/Bonus/CartCredit';
import CartDebit from '../../../../components/Bonus/CartDebit';
import {
  getNextScreenData,
  getCanGoNextCartScreenData,
} from '../../../../reducers';
import * as canGo from '../../../../features/cart/can-go-next-screen';
import * as nextScreen from '../../../../features/cart/get-next-screen';

const View = () => {
  const dispatch = useDispatch();
  const canGoNextCartScreenData = useSelector(getCanGoNextCartScreenData, eq);
  const [can, why] = canGo.bonuses(canGoNextCartScreenData);
  const nextScreenData = useSelector(getNextScreenData, eq);
  const where = nextScreen.bonuses(nextScreenData);
  return (
    <>
      <Header />
      <Container className="py-4">
        <PageHeader text="Бонусные баллы  " />
        <Stepper />
        <Row>
          <Col xs={12} md={7} lg={8}>
            <Row xs={1}>
              <Col>
                <Balance />
                <CartCredit />
                <CartDebit />
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={5} lg={4}>
            <Totals
              canGo={can}
              ifNotWhy={why}
              where={where}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default View;
