import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import Liability from './Liability';
import Order from './Order';
import Return from './Return';
import Address from './Address';
import DeliveryDate from './DeliveryDate';
import Cargo from './Cargo';
import Message from './Message';
import BonusCredit from './BonusCredit';
import BonusDebit from './BonusDebit';
import './style.scss';

const View = ({ canGo, ifNotWhy, where }) => {
  const history = useHistory();
  return (
  <div className="totals p-3">
    <Liability />
    <Order />
    <Return />
    <DeliveryDate />
    <Cargo />
    <BonusCredit />
    <BonusDebit />
    <Address
      isLoaded={canGo}
    />
    <Row>
      <Col>
        <div className="dash my-4"> </div>
        <Message text={ifNotWhy} />
        <Button
          variant="primary"
          className="w-100 my-2"
          disabled={!canGo}
          onClick={() => history.push(where)}
        >
          Продолжить
        </Button>
      </Col>
    </Row>
  </div>
)};

export default View;
