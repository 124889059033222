import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Code from '../screens/auth/Code';
import Phone from '../screens/auth/Phone';

const Nav = () => (
  <Switch>
    <Route path="/code" component ={Code} />
    <Route path="/phone" component ={Phone} />
  </Switch>
);

export default Nav;
