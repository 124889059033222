import React, { useState } from 'react';
import eq from 'lodash/fp/eq';
import at from 'lodash/at';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import find from 'lodash/find';
import toInteger from 'lodash/toInteger';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { getDelivery } from '../../../../reducers';
import AddressModal from '../../../AddressModal';
import { actions } from '../../../../features/items';

const hide = (delivery) => {
  const {length} = get(delivery, 'addresses', []);
  return length < 1;
};

const getCurrent = ({ addresses, to }) => {
  const id = toInteger(to);
  const [address] = at(
    find(addresses, { id }),
    'address',
  );
  return address;
};

const View = ({ isLoaded }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const delivery = useSelector(getDelivery, eq);
  const setAddress = async (to) => dispatch(actions.setAddress({ to }));
  const setTo = async (to) => {
    await setAddress(to);
    setShow(false);
  };
  const setClose = () => {
    if (isNil(to)) return;
    setShow(false);
  };
  const current = getCurrent(delivery);
  const { addresses, to } = delivery;
  const isOpen = show || isNil(to);
  if (hide(delivery)) return null;
  if (!isLoaded) return null;
  return (
    <Row className="my-2">
      <Col>
        <div className="title">
          Адрес доставки:
        </div>
      </Col>
      <Col>
        <div className="title float-end">
          <div
            onClick={() => setShow(true)}
            onKeyDown={() => setShow(true)}
            role="link"
            tabIndex={0}
            className="link"
          >
            {current}
          </div>
        </div>
        <AddressModal
          isOpen={isOpen}
          addresses={addresses}
          setTo={setTo}
          setClose={setClose}
          to={to}
        />
      </Col>
    </Row>
  );
};

export default View;
