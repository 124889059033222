import { createAsyncThunk } from '@reduxjs/toolkit';
import request from '../../../utils/request/index';

export default createAsyncThunk(
  'items/fetch-by-cat',
  async ({ token, catID }, { rejectWithValue }) => {
    try {
      const { data } = await request(token).itemsFetchByCatID({ catID });
      const { items } = data;
      return { catID, items };
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  },
);
