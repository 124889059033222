import get from 'lodash/get';
import { toPositive } from '../../../utils';
import {
  getClosest,
  getQty,
  getRet,
  setQty,
  setRet,
} from './methods-001';

const getQuantum = (state, itemID) => get(state, `byID.[${itemID}].quantum`, 0);

export const addQty = (state, { itemID, qty }) => setQty(
  state,
  itemID,
  getQty(state, itemID) + qty,
);

export const decQty = (state, { payload: { itemID } }) => {
  const qty = toPositive(getQty(state, itemID) - getQuantum(state, itemID));
  const ret = getRet(state, itemID);
  if (qty < ret) state = setRet(state, itemID, qty);
  if (qty < 1) return setQty(state, itemID, 0);
  return setQty(state, itemID, qty);
};

export const decRet = (state, { payload: { itemID } }) => {
  const ret = toPositive(getRet(state, itemID) - 1);
  if (ret < 1) return setRet(state, itemID, 0);
  return setRet(state, itemID, ret);
};

export const incQty = (state, { payload: { itemID } }) => setQty(
  state,
  itemID,
  getQty(state, itemID) + getQuantum(state, itemID),
);

export const incRet = (state, { payload: { itemID } }) => setRet(
  state,
  itemID,
  getRet(state, itemID) + 1,
);

export const putQty = (state, { payload: { itemID, qty } }) => {
  const add = toPositive(qty);
  if (add < 1) return state;
  const qtm = getQuantum(state, itemID);
  return setQty(state, itemID, getClosest(add, qtm));
};

export const putRet = (state, { payload: { itemID, qty } }) => {
  const ret = toPositive(qty);
  if (ret < 1) return setRet(state, itemID, 0);
  return setRet(state, itemID, ret);
};
