import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import Header from '../../components/Header';

const Layout = ({ Content }) => (
  <>
    <Header />
    <Container className="py-4">
      {Content}
    </Container>
  </>
);

Layout.propTypes = {
  Content: PropTypes.shape({}).isRequired,
};

export default Layout;
