import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { IconContext } from 'react-icons';
import { FaHistory } from 'react-icons/fa';

const Screen = () => (
  <>
    <h2>История заказов</h2>
    <Row>
      <Card className="my-1 py-3">
        <Row>
          <Col xs={12}>
            <div className="empty py-5">
              <IconContext.Provider value={{ size: '125px' }}>
                <div>
                  <FaHistory />
                </div>
              </IconContext.Provider>
            </div>
          </Col>
          <Col xs={12}>
            <div className="empty py-5 text">
              тут пока ничего нет
            </div>
          </Col>
        </Row>
      </Card>
    </Row>
  </>
);

export default Screen;
