import React from 'react';
import { IconContext } from 'react-icons';
import { GoSearch } from 'react-icons/go';
import '../style.scss';

const View = () => (
  <div className="icon icon-main">
    <IconContext.Provider value={{ size: '20px' }}>
      <div>
        <GoSearch />
      </div>
    </IconContext.Provider>
    <span>Найти</span>
  </div>
);

export default View;
