import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import reducer from './reducers';

const config = {
  key: 'root',
  storage,
  whitelist: [
    'auth',
    'features',
    'cats',
  ],
};

const store = configureStore({
  reducer: persistReducer(config, reducer),
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [
    thunk,
  ]
});

const persistor = persistStore(store);

export {
  store,
  persistor,
};
