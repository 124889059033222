import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isGuest } from '../reducers';
import Guest from './Guest';
import User from './User';

const toPrivateRender = ({ Component, isGuest }) => (props) => {
  if (isGuest) return <Redirect to="/phone" />;
  return <Component {...props} />;
};

const PrivateRoute = ({ component: Component, isGuest, ...rest }) => (
  <Route {...rest} render={toPrivateRender({ Component, isGuest })} />
);

const toPublicRender = ({ Component, isGuest }) => (props) => {
  if (!isGuest) return <Redirect to="/cats" />;
  return <Component {...props} />;
};

const PublicRoute = ({ component: Component, isGuest, ...rest }) => (
  <Route {...rest} render={toPublicRender({ Component, isGuest })} />
);

export default () => {
  const is = useSelector(isGuest);
  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute path="/phone" component={Guest} isGuest={is} />
        <PublicRoute path="/code" component={Guest} isGuest={is} />
        <PrivateRoute path="/" component={User} isGuest={is} />
      </Switch>
    </BrowserRouter>
  );
};
