import reduce from 'lodash/reduce';

export const appendToken = (data, token) => {
  if (typeof token !== 'string') return { ...data };
  if (`${token}` === '') return { ...data };
  return { ...data, access_token: token };
};

const put2Query = (out, val, key) => [...out, `${key}=${val}`];

const initQuery = (token) => put2Query([], token, 'access_token');

export const query = (token, data) => reduce(data, put2Query, initQuery(token));

export const url = (host, uri, ver) => `${host}/v${ver}/${uri}`;
