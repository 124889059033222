import React from 'react';
import Layout from '../../../layouts/Empty';
import View from './View';

const Screen = () => (
  <Layout
    Content={<View />}
  />
);

export default Screen;
