import { getStatus, getTotal, prepareLines } from './methods-002';

export const prepare = (order) => {
  const total = getTotal(order);
  const status = getStatus(order);
  const lines = prepareLines([ ...order.lines ]);
  return {
    ...order,
    total,
    status,
    lines,
  };
};

export default prepare;
