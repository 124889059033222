import React from 'react';
import eq from 'lodash/fp/eq';
import find from 'lodash/find';
import toInteger from 'lodash/toInteger';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import {
  getShipments,
  getHasShipments,
} from '../../../../reducers';

const View = () => {
  const has = useSelector(getHasShipments, eq);
  const { day, lines } = useSelector(getShipments, eq);
  if (!has) return null;
  const date = find(lines, { value: toInteger(day) });
  if (!date) return null;
  return (
    <Row className="my-1">
      <Col>
        <div className="title">
          День доставки:
        </div>
        <div className="dash my-3 w-50"> </div>
      </Col>
      <Col>
        <div className="title float-end">
          {date.label}
        </div>
      </Col>
    </Row>
  );
}

export default View;
