import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import filter from 'lodash/filter';
import pipe from 'lodash/fp/pipe';
import prop from 'lodash/fp/prop';
import toString from 'lodash/fp/toString';
import { toPositive } from '../../../utils';

export const getLines = (items) => mapValues(
  keyBy(
    filter(items, 'get.qty'),
    'id',
  ),
  'get.qty',
);

export const getReturns = (items) => mapValues(
  keyBy(
    filter(items, 'ret.qty'),
    'id',
  ),
  'ret.qty',
);

const rejectStatus = pipe(
  prop('status'),
  toString,
  (str) => str !== 'ok'
);

const rejectOrderID = pipe(
  prop('orderID'),
  toPositive,
  (int) => int < 1,
);

export const rejected = (payload) => {
  if (rejectStatus(payload)) return true;
  if (rejectOrderID(payload)) return true;
  return false;
};
