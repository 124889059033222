import React from 'react';
import isNil from 'lodash/fp/isNil';
import { Row, Col } from 'react-bootstrap';
import IconInfo from '../../../icons/Info';

const View = ({ text }) => {
  if (isNil(text)) return null;
  if (`${text}` === '') return null;
  return (
    <Row>
      <Col>
        <div className="informer warning rounded">
          <IconInfo />
          <div className="text">
            {text}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default View;
