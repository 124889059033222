import { createSlice } from '@reduxjs/toolkit';
import concat from 'lodash/fp/concat';
import pipe from 'lodash/fp/pipe';
import prop from 'lodash/fp/prop';
import min from 'lodash/fp/min';
import { toPositive } from '../../utils';

const steps = {
  cart: 0,
  shipment: 1,
  delivery: 2,
  bonuses: 3,
  confirm: 4,
};

export const step = pipe(
  prop('step'),
  toPositive,
);

const ensureStep = pipe(
  toPositive,
  concat(steps.confirm),
  min,
);

const setStep = (state, action) => {
  const step = pipe(
    prop('payload.step'),
    ensureStep,
  )(action);
  return { ...state, step };
};

const initialState = {
  step: steps.cart,
  isLoaded: false,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setStep,
  },
  extraReducers: {
    'auth/exit/pending': () => initialState,
    'items/fetch-cart-data/pending': (state) => ({ ...state, isLoaded: false }),
    'items/fetch-cart-data/fulfilled': (state) => ({ ...state, isLoaded: true }),
  },
});

export const { actions } = cartSlice;

export default cartSlice.reducer;
