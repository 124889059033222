import {
  goConfirm,
  goBonuses,
  goReturns,
  goShipments
} from './methods-001';

const Finder = ({ found, data }) => ({
  check: (fn) => {
    if (found) return Finder({ found });
    return Finder({
      found: fn(data),
      data,
    });
  },
  to: () => found,
});

export const cart = (data) => Finder({ data })
  .check(goReturns)
  .check(goShipments)
  .check(goBonuses)
  .check(goConfirm)
  .to();

export const returns = (data) => Finder({ data })
  .check(goShipments)
  .check(goBonuses)
  .check(goConfirm)
  .to();

export const shipments = (data) => Finder({ data })
  .check(goBonuses)
  .check(goConfirm)
  .to();

export const bonuses = (data) => Finder({ data })
  .check(goConfirm)
  .to();
