import format from '../../../../utils/format/orders';

export const fulfilled = (state, { payload: { orders } }) => {
  const { allIDs } = orders;
  const byID = format(orders.byID);
  return {
    ...state,
    byID,
    allIDs,
  };
};

export default fulfilled;
