import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { IconContext } from 'react-icons';
import { AiOutlineFileDone } from 'react-icons/ai';
import { actions } from '../../../../features/items';

const View = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.clearCart());
  }, []);
  return (
    <Row>
      <Card className="my-1 py-3">
        <Row>
          <Col xs={12}>
            <div className="empty py-5">
              <IconContext.Provider value={{ size: '125px' }}>
                <div>
                  <AiOutlineFileDone />
                </div>
              </IconContext.Provider>
            </div>
          </Col>
          <Col xs={12}>
            <p className="empty py-3 text title">
              Ура, заказ создан!
            </p>
            <p className="empty py-1 text">
              Что дальше?
            </p>
            <Row className="d-flex justify-content-center mb-3">
              <Link to="/cats" className="btn btn-primary mx-1 my-2 w-25">
                Создать ещё один заказ
              </Link>
              <Link to="/orders" className="btn btn-secondary mx-1 my-2 w-25">
                Перейти в историю заказов
              </Link>
            </Row>
          </Col>
        </Row>
      </Card>
    </Row>
  );
};

export default View;
