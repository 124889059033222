import React, { useEffect } from 'react';
import eq from 'lodash/fp/eq';
import pipe from 'lodash/fp/pipe';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from "react-router";
import { Col, Container, Row } from 'react-bootstrap';
import {
  getDebts,
  getToken,
  getItemIDsAtCart,
  getFeaturesStatus,
  getNextScreenData,
  getCanGoNextCartScreenData,
} from '../../../../reducers';
import * as canGo from '../../../../features/cart/can-go-next-screen';
import * as nextScreen from '../../../../features/cart/get-next-screen';
import fetchCartData from '../../../../features/items/fetch-cart-data';
import Header from '../../../../components/Header';
import Stepper from '../../../../components/cart/Stepper';
import Totals from '../../../../components/cart/Totals';
import PageHeader from '../../../../components/PageHeader';
import Item from '../Item';

const View = ({ methods }) => {
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const debts = useSelector(getDebts, eq);
  const itemIDs = useSelector(getItemIDsAtCart, eq);
  const features = useSelector(getFeaturesStatus, eq);
  const canGoNextCartScreenData = useSelector(getCanGoNextCartScreenData, eq);
  const [can, why] = canGo.cart(canGoNextCartScreenData);
  const nextScreenData = useSelector(getNextScreenData, eq);
  const where = nextScreen.cart(nextScreenData);
  useEffect(() => {
    const load = async () => {
      await dispatch(fetchCartData({ token }));
    };
    load();
  }, []);
  if (itemIDs.length < 1) return <Redirect to="/cart/empty" />;
  if (debts.isExceeded) return <Redirect to="/cart/blocked" />;
  return (
    <>
      <Header />
      <Container className="py-4">
        <PageHeader text="Корзина" />
        <Stepper />
        <Row>
          <Col xs={12} md={7} lg={8}>
            <Row xs={1}>
              <Col>
                {itemIDs.map((itemID) => (
                  <Item
                    itemID={itemID}
                    key={`${itemID}`}
                    methods={methods}
                    features={features}
                  />
                ))}
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={5} lg={4}>
            <Totals
              canGo={can}
              ifNotWhy={why}
              where={where}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default View;
