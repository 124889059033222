import { createAsyncThunk } from '@reduxjs/toolkit';
import request from '../../../../utils/request/index';

const ONE_HOUR = 1 * 60 * 60 * 1000;

const notUpdate = ({ updatedAt }) => {
  const diff = Date.now() - updatedAt;
  return diff < ONE_HOUR;
};

export default createAsyncThunk(
  'features/fetch',
  async ({ token, storedFeatures }, { rejectWithValue }) => {
    try {
      if (notUpdate(storedFeatures)) return storedFeatures;
      const response = await request(token).featuresFetch();
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  },
);
