import React from 'react';
import eq from 'lodash/fp/eq';
import { useSelector } from 'react-redux';
import { getItem } from '../../../../reducers';
import Card from '../../../../components/Items/Cart/Card';

const View = ({ itemID, methods, features }) => {
  const item = useSelector((state) => getItem(state)(itemID), eq);
  if (!item) return null;
  return (
    <Card
      item={item}
      methods={methods}
      features={features}
      isRet={true}
    />
  );
};

export default View;
