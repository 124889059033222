import curry from 'lodash/fp/curry';
import pipe from 'lodash/fp/pipe';
import toInteger from 'lodash/fp/toInteger';

export { centsToDollars, fetchDollarsCents } from './amount';

const max = curry(Math.max);

const notNegative = max(0);

export const toPositive = pipe(
  toInteger,
  notNegative,
);
