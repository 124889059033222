import getVal from 'lodash/get';
import isNil from 'lodash/isNil';
import map from 'lodash/fp/map';
import pipe from 'lodash/fp/pipe';
import prop from 'lodash/fp/prop';
import props from 'lodash/fp/props';
import { toPositive } from '../index';
import { formatUnit } from '../format/items/methods-002';
import {
  calcBase,
  calcDisc,
  mergeRet,
} from './methods-001';

export const initAmount = (item, qty) => {
  const base = calcBase(item, qty);
  const disc = calcDisc(item, qty);
  const corr = 0;
  const total = base - disc + corr;
  return {
    base,
    disc,
    corr,
    total,
  };
};

export const calcBonuses = pipe(
  props(['get.qty', 'ret.qty', 'bonuses']),
  map(toPositive),
  ([get, ret, bonuses]) => [get - ret, bonuses],
  map(toPositive),
  ([diff, bonuses]) => diff * bonuses,
);

export const getAmountQty = (item, node) => {
  const qty = getVal(item, `${node}.qty`, 0);
  const amount = initAmount(item, qty);
  return { amount, qty };
};

export const initUnit = (item, qty) => {
  const { weight, weightPrice } = item;
  if (weightPrice < 1) return { weight: qty, unit: '' };
  return formatUnit(qty * weight);
};

export const merge = (prev, next) => {
  if (isNil(prev)) return next;
  const get = getVal(prev, 'get');
  const ret = mergeRet(prev, next, getAmountQty(next, 'ret'));
  return {
    ...prev,
    ...next,
    get,
    ret,
  };
};

export const notPhotos = pipe(
  prop('photos.length'),
  toPositive,
  (length) => length < 1,
);
