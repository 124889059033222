import React from 'react';
import props from 'lodash/fp/props';
import { fetchDollarsCents } from '../../../utils';
import './style.scss';

const getDolCen = (qty, amount, price) => {
  if (qty > 0) return fetchDollarsCents(amount);
  return props(['dol', 'cen'], price);
};

const Old = ({ item }) => {
  const [
    price,
    qty,
    amount,
    disc,
  ] = props([
    'prices.old',
    'get.qty',
    'get.amount.total',
    'get.amount.disc',
  ], item);
  if (price.raw < 1) return null;
  const [dol, cen] = getDolCen(qty, amount + disc, price);
  return (
    <div className="price old">
      {dol}
      <small>{`,${cen}`}</small>
      <span>₽</span>
    </div>
  );
};

const itemFigs = ({ item, isRet }) => {
  if (isRet) return props([
    'prices.cur',
    'ret.qty',
    'ret.amount.total',
  ], item);
  return props([
    'prices.cur',
    'get.qty',
    'get.amount.total',
  ], item);
};

const Cur = ({ item, isRet }) => {
  const [
    price,
    qty,
    amount,
  ] = itemFigs({ item, isRet });
  if (price.raw < 1) return null;
  const [dol, cen] = getDolCen(qty, amount, price);
  return (
    <div className="price new">
      {dol}
      <small>{`,${cen}`}</small>
      <span>₽</span>
    </div>
  );
};

const Component = ({ item, isRet }) => (
  <div className="priceGroup">
    <div className="priceInner">
      <Old item={item} />
      <Cur item={item} isRet={isRet} />
    </div>
  </div>
);

export default Component;
