import React from 'react';
import Slider, { SliderTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';

const { Handle } = Slider;

const handle = (props) => {
  const {
    value,
    dragging,
    index,
    ...rest
  } = props;
  return (
    <SliderTooltip
      prefixCls="rc-slider-tooltip"
      overlay={`${value}`}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...rest} />
    </SliderTooltip>
  );
};

const View = ({ max, setDec }) => {
  return (
    <div className="p-2">
      <Slider
        step={1}
        min={0}
        max={max}
        onChange={setDec}
        handle={handle}
        railStyle={{
          height: 4,
        }}
        handleStyle={{
          height: 24,
          width: 24,
          marginLeft: 0,
          marginTop: -10,
          backgroundColor: '#009fe3',
          border: 0,
        }}
      />
    </div>
  );
};

export default View;
