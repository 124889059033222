import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import PriceGroup from '../../PriceGroup';
import ButtonsGroup from '../../ButtonsGroup';
import Weight from '../../Weight';
import Icons from '../../../icons/IconsBar';
import ItemModal from '../../Modal';
import IconTrash from '../../../icons/Trash';
import '../../style.scss';

const Component = ({ item, features, methods, isRet }) => {
  const [show, setShow] = useState(false);
  return (
    <Card className="line-view mb-1">
      <Row>
        <Col xs={3}>
          <Icons item={item} methods={methods} />
          <div
            className="card-img-inner m-auto"
            onClick={() => setShow(true)}
            onKeyDown={() => setShow(true)}
            role="link"
            tabIndex={0}
          >
            <Card.Img
              variant="top"
              src={item.photo}
              className="mt-4 my-lg-auto"
            />
          </div>
          <ItemModal
            show={show}
            setClose={() => setShow(false)}
            item={item}
            features={features}
            methods={methods}
          />
        </Col>
        <Col>
          <Card.Body className="p-2">
            <Row>
              <Col xs={12} md={11}>
                <Card.Title className="itemName">
                  {item.name}
                </Card.Title>
              </Col>
            </Row>
            <Row>
              <Col xs={6} md={3} ld={4}>
                <Card.Text>
                  <Weight item={item} />
                </Card.Text>
              </Col>
              <Col xs={6} md={4} ld={4}>
                <div className="float-end float-md-start">
                  <PriceGroup item={item} isRet={isRet} />
                </div>
              </Col>
              <Col xs={12} md={5} ld={4}>
                <ButtonsGroup
                  item={item}
                  methods={methods}
                  isRet={isRet}
                />
              </Col>
            </Row>
          </Card.Body>
        </Col>
      </Row>
    </Card>
  );
};

Component.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    photo: PropTypes.string.isRequired,
  }).isRequired,
};

export default Component;
