import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actions as cartActions } from '../../../features/cart';
import View from './View';

const Screen = () => {
  const dispatch = useDispatch();
  useEffect(() => dispatch(cartActions.setStep({ step: 4 })), []);
  return (<View />);
};

export default Screen;
