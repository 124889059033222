import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar/User';

const Layout = ({ match, Content }) => (
  <>
    <Header />
    <Container className="py-4">
      <Row>
        <Col xs={12} md={3}>
          <Sidebar match={match} />
        </Col>
        <Col xs={12} md={9}>
          {Content}
        </Col>
      </Row>
    </Container>
  </>
);

Layout.propTypes = {
  Content: PropTypes.shape({}).isRequired,
};

export default Layout;
