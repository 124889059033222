import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import { getToken } from '../../reducers'
import copyOrder from '../../features/items/copy-order';
import IconCopy from '../icons/Copy';
import IconDone from '../icons/Done';
import IconView from '../icons/Eye';
import OrderModal from './Modal';
import './style.scss';

const copyStatuses = {
  idle: 0,
  progress: 1,
  done: 2,
};

const Copy = ({ copyStatus, handleCopy }) => {
  if (copyStatus === copyStatuses.progress) return (
    <Spinner
      animation="border"
      role="status"
      variant="primary"
      title="Копирую заказ..."
      >
      <span className="visually-hidden">Копирую заказ...</span>
    </Spinner>
  );
  if (copyStatus === copyStatuses.done) return (
    <div className="my-1">
      <IconDone />
    </div>
  );
  return (
    <div className="my-1" onClick={handleCopy}>
      <IconCopy />
    </div>
  );
}

const Component = ({ order }) => {
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const [show, setShow] = useState(false);
  const [copyStatus, setCopy] = useState(copyStatuses.idle);
  const handleCopy = async () => {
    setCopy(copyStatuses.progress);
    await dispatch(copyOrder({ token, order }));
    setCopy(copyStatuses.done);
    setTimeout(() => setCopy(copyStatuses.idle), 2000);
  };
  return (
    <>
      <Card className="my-1 py-3">
        <Row>
          <Col>
            <Row className="mb-2">
              <Col xs={6} md={6} lg={3}>
                <div className="term note">Заказ №</div>
                <div className="title">
                  {order.id}
                </div>
              </Col>
              <Col xs={6} md={6} lg={3}>
                <div className="term note">от</div>
                <div className="title">
                  {order.created}
                </div>
              </Col>
              <Col xs={6} md={6} lg={3}>
                <div className="term note">Сумма:</div>
                <div className="title">
                  {order.total.amount}
                </div>
              </Col>
              <Col xs={6} md={6} lg={3}>
                <div className="term note">Статус:</div>
                <div className="title">
                  {order.status.name}
                </div>
              </Col>
            </Row>
            { order.shipment &&
              order.shipment.when &&
              order.shipment.when.date && 
              <Row className="mt-2">
                <Col>
                  <div className="term note">Дата доставки:</div>
                  <div className="title">
                    {order.shipment.when.date}
                  </div>
                </Col>
              </Row>
            }
            { order.shipment &&
              order.shipment.when &&
              order.shipment.when.from &&
              order.shipment.when.from && 
              <Row>
                <Col>
                  <div className="term note">Время доставки:</div>
                  <div className="title">
                    {order.shipment.when.from} - {order.shipment.when.to}
                  </div>
                </Col>
              </Row>
            }
            { order.shipment &&
              order.shipment.where && 
              <Row className="mt-2">
                <Col>
                  <div className="term note">Адрес доставки:</div>
                  <div className="title">
                    {order.shipment.where.address}
                  </div>
                </Col>
              </Row>
            }
          </Col>
          <Col xs={1}>
            <div className="float-end icon-list">
              <ul>
                <li className="p-1">
                  <Copy handleCopy={handleCopy} copyStatus={copyStatus} />
                </li>
                <li className="p-1">
                  <div className="my-1">
                    <div
                      onClick={() => setShow(true)}
                      onKeyDown={() => setShow(true)}
                      role="link"
                      tabIndex={0}
                    >
                      <IconView />
                    </div>
                    <OrderModal
                      show={show}
                      setClose={() => setShow(false)}
                      order={order}
                      handleCopy={handleCopy}
                      copyStatus={copyStatus}
                      copyStatuses={copyStatuses}
                    />
                  </div>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default Component;
