import React from 'react';
import { Col, Row } from 'react-bootstrap';

const View = ({ text }) => (
  <Row>
    <Col>
      <h2>
        {text}
      </h2>
    </Col>
  </Row>
);

export default View;
