import concat from 'lodash/fp/concat';
import get from 'lodash/get';
import pipe from 'lodash/fp/pipe';
import toInteger from 'lodash/fp/toInteger';
import zipObj from 'lodash/fp/zipObj';
import { fetchDollarsCents } from '../amount';
import { calcAmount } from './methods-002';

const getPrice = (path) => (item) => get(item, path, 0);

const getPriceCur = getPrice('prices.cur.raw');

const getPriceOld = getPrice('prices.old.raw');

export const calcBase = (item, qty) => {
  const priceOld = getPriceOld(item);
  if (priceOld > 0) return calcAmount(item, qty, priceOld);
  const priceCur = getPriceCur(item);
  return calcAmount(item, qty, priceCur);
};

export const calcDisc = (item, qty) => {
  const priceOld = getPriceOld(item);
  if (priceOld < 1) return 0;
  const priceCur = getPriceCur(item);
  const disc = calcAmount(item, qty, priceOld) - calcAmount(item, qty, priceCur);
  if (disc > 0) return disc;
  return 0;
};

export const fillAmount = pipe(
  toInteger,
  (amount) => concat(
    [amount],
    fetchDollarsCents(amount),
  ),
  zipObj(['amount', 'dol', 'cen']),
);

export const mergeRet = (prev, next, initAmoutQty) => {
  const returns = get(next, 'returns', 0);
  if (returns === 0) return initAmoutQty;
  return prev.ret;
};
