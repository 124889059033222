import { combineReducers } from 'redux';
import authReducer, * as fromAuth from '../features/auth';
import cartReducer, * as fromCart from '../features/cart';
import catsReducer, * as fromCats from '../features/cats';
import featuresReducer, * as fromFeatures from '../features/features';
import itemsReducer, * as fromItems from '../features/items';
import ordersReducer, * as fromOrders from '../features/orders';

export const isCode = (state) => fromAuth.isCode(state.auth);
export const isPhone = (state) => fromAuth.isPhone(state.auth);
export const isGuest = (state) => fromAuth.isGuest(state.auth);
export const isUser = (state) => fromAuth.isUser(state.auth);
export const getToken = (state) => fromAuth.getToken(state.auth);

export const getStep = ({ cart }) => fromCart.step(cart);

export const getCat = (state, catID) => fromCats.cat(state.cats, catID);
export const getCats = (state) => fromCats.cats(state.cats);
export const getFirstCatID = (state) => fromCats.firstCatID(state.cats);

export const getFeatures = ({ features }) => fromFeatures.features(features);
export const getFeaturesStatus = ({ features }) => fromFeatures.featuresStatus(features);

export const getBonusesTrans = (state) => fromItems.bonusesTrans(state.items);
export const getBonusesTos = (state) => fromItems.bonusesTos(state.items);
export const getBonusesDecInc = ({ items }) => fromItems.bonusesDecInc(items);
export const getBonusesBalance = (state) => fromItems.bonusesBalance(state.items);
export const getBonusesMax = (state) => fromItems.bonusesMax(state.items);
export const getDebts = ({ items }) => fromItems.getDebts(items);
export const getReturns = (state) => fromItems.returns(state.items);
export const getLimits = ({ items }) => fromItems.limits(items);
export const getDelivery = ({ items }) => fromItems.delivery(items);
export const getHasShipments = ({ items }) => fromItems.hasShipments(items);
export const getShipments = ({ items }) => fromItems.shipments(items);
export const getItem = ({ items }) => fromItems.item(items);
export const getTotal = ({ items }) => fromItems.total(items);
export const getItemIDsAtCart = ({ items }) => fromItems.itemIDsAtCart(items);
export const getItemIDsToReturn = ({ items }) => fromItems.itemIDsToReturn(items);
export const getItemsToPost = ({ items }) => fromItems.itemsToPost(items);
export const getItemIDsByCat = ({ items }, catID) => fromItems.itemIDsByCat(items)(catID);
export const getNextScreenData = ({ items, features }) => ({
  ...fromItems.nextScreenData(items),
  ...fromFeatures.nextScreenData(features),
});

export const getOrders = ({ orders }) => fromOrders.getOrders(orders);

export const getCanGoNextCartScreenData = ({ cart, items }) => ({
  cart,
  debts: fromItems.getDebts(items),
  limits: fromItems.limits(items),
  returns: fromItems.returns(items),
  shipments: fromItems.shipments(items),
  totals: fromItems.total(items),
});

export default combineReducers({
  auth: authReducer,
  cart: cartReducer,
  cats: catsReducer,
  features: featuresReducer,
  items: itemsReducer,
  orders: ordersReducer,
});
