import axios from 'axios';
import eq from 'lodash/fp/eq';
import toLower from 'lodash/fp/toLower';
import toString from 'lodash/fp/toString';
import pipe from 'lodash/fp/pipe';
import {
  appendToken,
  url,
  query,
} from './methods-004';

const isVerb = (verb) => pipe(
  toString,
  toLower,
  eq(verb),
);

export const isDelete = isVerb('delete');
export const isGet = isVerb('get');
export const isPost = isVerb('post');
export const isPut = isVerb('put');

export const get = (params) => axios({
  method: 'get',
  url: `${url(params.host, params.uri, params.apiVer)}?${query(params.token, params.data).join('&')}`,
});

const request = (verb) => (params) => axios({
  method: verb,
  url: url(params.host, params.uri, params.apiVer),
  data: appendToken(params.data, params.token),
});

export const post = request('post');

export const put = request('put');

export const remove = request('delete');
