import { createSlice } from '@reduxjs/toolkit';
import isEqual from 'lodash/fp/isEqual';
import pipe from 'lodash/fp/pipe';
import prop from 'lodash/fp/prop';
import toString from 'lodash/fp/toString';
import { toPositive } from '../../utils';
import code from './code/fulfilled';
import phone from './phone/fulfilled';

const initialState = {
  token: '',
  step: 0,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: {
    'auth/code/fulfilled': code,
    'auth/phone/fulfilled': phone,
    'auth/exit/pending': () => initialState,
    'auth/exit/rejected': () => initialState,
    'auth/exit/fulfilled': () => initialState,
  },
});

const STEP_PHONE = 0;

const STEP_CODE = 1;

const STEP_USER = 2;

const isCurrentStep = (step) => pipe(
  prop('step'),
  toPositive,
  isEqual(step),
);

export const isCode = isCurrentStep(STEP_CODE);

export const isPhone = isCurrentStep(STEP_PHONE);

export const isGuest = (state) => isCode(state) || isPhone(state);

export const isUser = isCurrentStep(STEP_USER);

export const getToken = pipe(
  prop('token'),
  toString,
);

export default authSlice.reducer;
