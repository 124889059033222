import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '../../../features/cart';
import View from './View';

const Screen = () => {
  const dispatch = useDispatch();
  useEffect(() => dispatch(actions.setStep({ step: 2 })), []);
  return (
    <View />
  );
};

export default Screen;
