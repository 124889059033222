import React from 'react';
import PropTypes from 'prop-types';
import prop from 'lodash/fp/prop';
import { Container, Col, Row, Button } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import Weight from '../Weight';
import PriceGroup from '../PriceGroup';
import ButtonsGroup from '../ButtonsGroup';
import IconsList from '../../icons/IconsList';

const hasReturns = ({ item, features }) => {
  if (item.returns < 1) return false;
  return features.isActiveReturns;
};

const Component = ({ item, methods, features, setClose, show }) => {
  if (!show) return null;
  return (
    <Modal show onHide={setClose}>
      <Modal.Header className="show-grid p-1 pt-3">
        <Button className="btn-close" onClick={setClose}> </Button>
        <Container>
          <Row>
            <Col xs={12}>
              <Modal.Title>
                {item.name}
                <Weight item={item} />
              </Modal.Title>
            </Col>
          </Row>
        </Container>
      </Modal.Header>
      <Modal.Body className="show-grid p-1">
        <Container>
          <Row>
            <Col xs={6}>
              <div className="my-3">
                <PriceGroup item={item} />
              </div>
              <IconsList item={item} features={features} />
            </Col>
            <Col xs={6}>
              <div className="card-img-inner my-auto" role="link">
                <Image className="card-img-top my-auto" src={item.photo} />
              </div>
            </Col>
          </Row>
          <Row className="my-3 bg-light py-2">
            <Col xs={6}>
              <div className="title">
                Купить:
              </div>
              <div className="note">
                {`продаётся по ${item.quantum} шт`}
              </div>
            </Col>
            <Col xs={6}>
              <ButtonsGroup item={item} methods={methods} />
            </Col>
          </Row>
          {hasReturns({ item, features }) && 
            <Row className="my-3 py-2">
              <Col xs={6}>
                <div className="title">
                  Вернуть:
                </div>
              </Col>
              <Col xs={6}>
                <ButtonsGroup item={item} methods={methods} isRet="true" />
              </Col>
            </Row>
          }
          {item.shelfLife > 0 &&
            <Row className="my-3 py-2">
              <Col xs={6}>
                <div className="dash mb-2" />
                <div className="title mb-1">
                  Срок годности:
                </div>
              </Col>
              <Col xs={12}>
                {`${item.shelfLife} сут`}
              </Col>
            </Row>
          }
          {item.composition && 
          <Row className="my-3 py-2">
            <Col xs={6}>
              <div className="dash mb-2" />
              <div className="title mb-1">
                Состав:
              </div>
            </Col>
            <Col xs={12}>
              {item.composition}
            </Col>
          </Row>
          }
          {item.description && 
            <Row className="my-3 py-2">
              <Col xs={6}>
                <div className="dash mb-2" />
                <div className="title mb-1">
                  Описание:
                </div>
              </Col>
              <Col xs={12}>
                {item.description}
              </Col>
            </Row>
          }
        </Container>
      </Modal.Body>
    </Modal>
  );
};

Component.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    photo: PropTypes.string.isRequired,
    composition: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    shelfLife: PropTypes.number.isRequired,
  }).isRequired,
  setClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default Component;
