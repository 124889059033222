import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import IconCart from '../icons/Cart';
import IconUser from '../icons/User';
import './style.scss';

const View = () => (
  <header role="banner" className="bg-white border-bottom">
    <Container>
      <Row>
        <Col xs={4}>
          <Navbar className="py-3">
            <Link to="/cats">
              <div className="logo" />
              <Navbar.Brand className="d-none d-sm-block py-0">
                Snarta
              </Navbar.Brand>
            </Link>
          </Navbar>
        </Col>
        <Col>
          <div className="d-flex justify-content-end py-3">
            <ul className="user-menu pt-1">
              <li className="m-0 ml-md-2">
                <Link to="/cart">
                  <IconCart />
                </Link>
              </li>
              <li className="ml-md-2">
                <Link to="/orders">
                  <IconUser />
                </Link>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </Container>
  </header>
);

export default View;
