export default {
  code: ['post', 'code'],
  exit: ['post', 'exit'],
  phone: ['post', 'phone'],
  push: ['post', 'push'],
  bonusesFetch: ['get', 'bonuses'],
  catsFetch: ['get', 'cats'],
  itemsFetchByCatID: ['get', 'items/by-cat'],
  itemsFetchByOrderID: ['get', 'items/by-order'],
  cartFetch: ['get', 'cart'],
  ordersCreate: ['post', 'orders'],
  ordersFetch: ['get', 'orders'],
  featuresFetch: ['get', 'features'],
};
