import { createSlice } from '@reduxjs/toolkit';
import createFulfilled from './create/fulfilled';
import fetchFulfilled from './fetch/fulfilled';
import { prepare } from './methods-001';

const initialState = {
  allIDs: [],
  byID: {},
};

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers: {
    'auth/exit/pending': () => initialState,
    'orders/create/fulfilled':  createFulfilled,
    'orders/fetch/my/fulfilled': fetchFulfilled,
  },
});

export const getOrder = (state, orderID) => prepare(state.byID[orderID]);

export const getOrders = (state) => state.allIDs.map((orderID) => getOrder(state, orderID));

export const actions = ordersSlice.actions;

export default ordersSlice.reducer;
