import get from 'lodash/get';
import reduce from 'lodash/reduce';
import pipe from 'lodash/fp/pipe';
import props from 'lodash/fp/props';
import toString from 'lodash/fp/toString';
import { toPositive } from '../../../../utils';
import { format } from '../../../../utils/format/shipments';
import { getQty, setQty } from '../../cart/methods-001';
import { getDeliveryTo } from './methods-002';

export const addCustToCart = (state, items) => reduce(items, (out, qty, itemID) => {
  const cur = getQty(state, itemID);
  if (cur > 0) return { ...out };
  return setQty(out, itemID, cur + qty);
}, state);

export const getBonuses = pipe(
  props([
    'bonuses.balance',
    'bonuses.tos',
  ]),
  ([balance, tos]) => ({
    balance: toPositive(balance),
    tos: toString(tos),
    use: 0,
  }),
);


const getDefaultAddress = (addresses, to) => {
  if (addresses.length !== 1) return to;
  const [{id}] = addresses;
  return id;
};

export const getDelivery = (state, { addresses }) => {
  const to = getDeliveryTo(
    addresses,
    get(state, 'delivery.to', undefined)
  );
  return { addresses, to: getDefaultAddress(addresses, to) };
};

export const getShipments = (payload) => ({
  day: undefined,
  trip: undefined,
  lines: format(payload.shipments),
});
