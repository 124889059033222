import pipe from 'lodash/fp/pipe';
import props from 'lodash/fp/props';
import zipObj from 'lodash/fp/zipObj';

export default (state, data) => pipe(
  props([
    'payload.allIDs',
    'payload.byID',
  ]),
  zipObj([
    'allIDs',
    'byID',
  ]),
)(data);
