import React from 'react';
import isEqual from 'lodash/fp/isEqual';
import { useSelector } from 'react-redux';
import { getItem } from '../../../reducers';
import Card from '../../../components/Items/Cats/Card';

const View = ({ itemID, features, methods }) => {
  const item = useSelector((state) => getItem(state)(itemID), isEqual);
  return <Card item={item} features={features} methods={methods} />;
};

export default View;
