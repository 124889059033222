import extend from 'lodash/fp/extend';
import pipe from 'lodash/fp/pipe';
import prop from 'lodash/fp/prop';
import toString from 'lodash/fp/toString';

const step = 1;

export default (state, data) => pipe(
  prop('payload.token'),
  toString,
  (token) => ({ step, token }),
  extend({ ...state }),
)(data);
