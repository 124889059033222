import map from 'lodash/fp/map';
import pipe from 'lodash/fp/pipe';
import props from 'lodash/fp/props';
import toInteger from 'lodash/fp/toInteger';

const getWeights = pipe(
  props(['weight', 'weightPrice']),
  map(toInteger),
);

const getAmount = (qty, price) => qty * price;

const getWeightKoef = (weight, weightPrice) => weight / weightPrice;

export const calcAmount = (item, qty, price) => {
  const [weight, weightPrice] = getWeights(item);
  if (weightPrice < 1) return getAmount(qty, price);
  return getAmount(qty, price) * getWeightKoef(weight, weightPrice);
};

export default calcAmount;
