import { mergeItems, prepare, put } from './methods-001';

export default (state, { payload }) => {
  const items = prepare(payload)
  const byID = mergeItems(state, items);
  const allIDs = Object.keys(byID);
  const { order: { lines } } = payload;
  return put(lines, {
    ...state,
    allIDs,
    byID,
  });
};
