import React, { useEffect, useState } from 'react';
import eq from 'lodash/fp/eq';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'react-bootstrap';
import { getToken, getOrders } from '../../../reducers'
import { actions } from '../../../features/orders';
import { fetchMy } from '../../../features/orders/fetch';
import Card from '../../../components/History';
import Empty from '../Empty';

const View = () => {
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const orders = useSelector(getOrders, eq);
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await dispatch(fetchMy({ token }));
      setLoading(false);
    };
    load();
  }, []);
  if (isLoading) return null;
  if (orders.length < 1) return <Empty />;
  return (
    <>
      <h2>История заказов</h2>
      <Row>
      {orders.map((order) => <Card order={order} key={`${order.id}`} />)}
      </Row>
    </>
  );
};

export default View;
