import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { Stepper } from 'react-form-stepper';
import { getStep } from '../../../reducers';

const View = () => {
  const step = useSelector(getStep);
  return (
    <Row className="d-none d-md-block my-2">
      <Col>
        <Stepper
          steps={[
            { label: 'Корзина' },
            { label: 'Возвраты' },
            { label: 'Дата доставки' },
            { label: 'Бонусы' },
            { label: 'Подтверждение заказа' },
          ]}
          activeStep={step}
          className="py-2 mb-2"
          styleConfig={{
            activeBgColor: '#009fe3',
            completedBgColor: '#056f9d',
          }}
        />
      </Col>
    </Row>
  );
};

export default View;
