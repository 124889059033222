import React from 'react';
import Layout from '../../layouts/User';
import View from './View';

const Screen = (props) => (
  <Layout
    match={props.match}
    Content={<View />}
  />
);

export default Screen;
