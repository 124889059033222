import React from 'react';
import { IconContext } from 'react-icons';
import { IoIosArrowRoundDown } from 'react-icons/io';
import '../style.scss';

const View = () => (
  <div className="icon icon-down">
    <IconContext.Provider value={{ size: '25px' }}>
      <div>
        <IoIosArrowRoundDown />
      </div>
    </IconContext.Provider>
  </div>
);

export default View;
