import React from 'react';
import eq from 'lodash/fp/eq';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { getBonusesDecInc } from '../../../reducers';
import IconUp from '../../icons/ArrowUp';

const View = () => {
  const { inc } = useSelector(getBonusesDecInc, eq);
  return (
    <Row className="my-2">
      <Card className="py-3">
        <Row>
          <Col>
            <div className="title">
              Баллов к зачислению:
            </div>
          </Col>
          <Col>
            <div className="title">
              {inc}
            </div>
          </Col>
          <Col xs={1}>
            <div className="float-end">
              <IconUp />
            </div>
          </Col>
        </Row>
      </Card>
    </Row>
  );
};

export default View;
