import React from 'react';
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ListGroup from 'react-bootstrap/ListGroup';
import { fetchDollarsCents } from '../../../../utils';
import '../../style.scss';

const Component = ({ item }) => {
  const [dol, cen] = fetchDollarsCents(item.get.amount.total);
  return (
    <ListGroup.Item as="li">
      <Row>
        <Col xs={2} className="px-0" >
          <div className="card-img-inner my-auto" role="link">
            <Image
              className="card-img-top my-auto"
              src={item.photo}
            />
          </div>
        </Col>
        <Col>
          <Row>
            <Col>
              {item.name}
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              {item.weightUnit.weight}
              {' '}
              {item.weightUnit.unit}
            </Col>
            <Col>
              {item.get.qty}
              {' '}
              шт.
            </Col>
            <Col>
              {`${dol}.${cen}`}
              <span>₽</span>
            </Col>
          </Row>
        </Col>
      </Row>
    </ListGroup.Item>
  );
};

export default Component;
