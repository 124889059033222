import extend from 'lodash/fp/extend';
import map from 'lodash/fp/map';
import pipe from 'lodash/fp/pipe';
import prop from 'lodash/fp/prop';
import props from 'lodash/fp/props';
import zipObj from 'lodash/fp/zipObj';
import { toPositive } from '../../index';
import { fetchDollarsCents } from '../../amount';

export const getBonuses = pipe(
  prop('bonuses'),
  toPositive,
);

const formatPrice = (raw) => pipe(
  fetchDollarsCents,
  zipObj(['dol', 'cen']),
  extend({ raw }),
)(raw);

const preparePrice = pipe(
  toPositive,
  formatPrice,
);

export const getPrices = pipe(
  props(['price', 'priceOld']),
  map(preparePrice),
  zipObj(['cur', 'old']),
);

const buildUnit = (unit) => (weight) => ({ unit, weight });

const gram = buildUnit('гр');

const kilo = buildUnit('кг');

const GRAMS_IN_KILO = 1000;

export const formatUnit = (weightInGrams) => {
  if (weightInGrams < GRAMS_IN_KILO) return gram(weightInGrams);
  return kilo(weightInGrams / GRAMS_IN_KILO);
};

export const isNearly = (weightPrice) => weightPrice > 0;
