import getVal from 'lodash/get';
import isNil from 'lodash/isNil';
import find from 'lodash/find';
import formatItem from '../../utils/format/items/methods-001';
import { getAmountQty, initUnit } from '../../utils/item';
import { centsToDollars } from '../../utils';
// import styles from '../../styles';

const statuses = {
  all: [
    {
      id: 0,
      name: 'Новый',
      // style: styles.historyStatusNew,
      method: 'new',
    },
    {
      id: 1,
      name: 'Получен',
      // style: styles.historyStatusSend,
      method: 'sent',
    },
    {
      id: 2,
      name: 'К отгрузке',
      // style: styles.historyStatusGet,
      method: 'ready',
    },
    {
      id: 3,
      name: 'Отгружен',
      // style: styles.historyStatusFull,
      method: 'ship',
    },
    {
      id: 4,
      name: 'Отменён',
      // style: styles.historyStatusCansel,
      method: 'cancel',
    },
  ],
  none: {
    id: -1,
    name: 'Нет',
    // style: styles.historyStatusCansel,
    method: '',
  },
};

export const getStatus = (order) => {
  const { all, none } = statuses;
  const status = find(all, { id: order.status });
  if (isNil(status)) return none;
  return status;
};

export const getTotal = ({ amount }) => ({
  amount: centsToDollars(amount)
});

export const prepareLines = (lines) => lines.map((line) => {
  const { qty } = line;
  const qtyCust = getVal(line, 'qtyCust', 0);
  const cartUnit = initUnit(line, qty);
  const custUnit = initUnit(line, qtyCust);
  const item = formatItem({
    ...line,
    get: {
      qty
    },
    cartUnit,
    custUnit,
  });
  const get = getAmountQty(item, 'get');
  return { ...item, get };
});
