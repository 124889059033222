import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { getBonusesBalance } from '../../../reducers';
import IconQuestion from '../../icons/Question';
import ItemModal from '../Modal';

const View = () => {
  const balance = useSelector(getBonusesBalance);
  const [show, setShow] = useState(false);
  return (
    <Row>
      <Card className="py-3">
        <Row>
          <Col>
            <div className="title">
              Накоплено баллов:
            </div>
          </Col>
          <Col>
            <div className="title">
              {balance}
            </div>
          </Col>
          <Col xs={1}>
            <div className="float-end">
              <div
                onClick={() => setShow(false)}
                onKeyDown={() => setShow(false)}
                role="link"
                tabIndex={0}
              >
                <IconQuestion />
              </div>
            </div>
            <ItemModal
              show={show}
              setClose={() => setShow(false)}
            />
          </Col>
        </Row>
      </Card>
    </Row>
  );
};

export default View;
