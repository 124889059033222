import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { IconContext } from 'react-icons';
import { FiPlus, FiMinus } from 'react-icons/fi';
import './style.scss';

const getDecIncQty = ({ item, methods, isRet }) => {
  const { id, get, ret } = item;
  const {
    decQty,
    incQty,
    putQty,
    decRet,
    incRet,
    putRet,
  } = methods;
  if (isRet) return [decRet(id), incRet(id), putRet(id), ret.qty];
  return [decQty(id), incQty(id), putQty(id), get.qty];
};

const Component = ({ item, methods, isRet }) => {
  const [dec, inc, put, qty] = getDecIncQty({ item, methods, isRet });
  const [next, setNext] = useState(qty);
  useEffect(() => setNext(qty), [qty, isRet]);
  const save = () => {
    put(next);
    setNext(qty);
  };
  return (
    <div className="w-100 d-flex justify-content-end">
      <div className="btnGroup mt-1">
        <div className="btn minus" onClick={dec}>
          <IconContext.Provider value={{ className: 'icon-btnGroup' }}>
            <div>
              <FiMinus />
            </div>
          </IconContext.Provider>
        </div>
        <Form.Control
          className="text-center"
          value={`${next}`}
          onChange={(event) => setNext(event.target.value)}
          onBlur={() => save()}
        />
        <div className="btn plus" onClick={inc}>
          <IconContext.Provider value={{ className: 'icon-btnGroup' }}>
            <div>
              <FiPlus />
            </div>
          </IconContext.Provider>
        </div>
      </div>
    </div>
  );
};

export default Component;
