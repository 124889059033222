import floor from 'lodash/floor';
import reduce from 'lodash/reduce';
import { initAmount } from '../../../utils/item';
import { getQtyVal } from './methods-002';

export const getClosest = (qty, qtm) => {
  const mnt = floor(qty / qtm);
  const left = mnt * qtm;
  const right = left + qtm;
  const useRight = left === 0 || (qty - left) > (right - qty);
  if (useRight) return right;
  return left;
};

export const getQty = getQtyVal('get');

export const getRet = getQtyVal('ret');

export const setQty = (state, itemID, qty) => {
  const item = { ...state.byID[itemID] };
  const qtyRet = Math.min(qty, getRet(state, itemID));
  const get = { ...item.get, qty, amount: initAmount(item, qty) };
  const ret = { ...item.ret, qty: qtyRet, amount: initAmount(item, qtyRet) };
  const byID = { ...state.byID, [itemID]: { ...item, get, ret } };
  return { ...state, byID };
};

export const setRet = (state, itemID, qtyRetNext) => {
  const item = { ...state.byID[itemID] };
  const qty = Math.min(getQty(state, itemID), qtyRetNext);
  const ret = { ...item.ret, qty, amount: initAmount(item, qty) };
  const byID = { ...state.byID, [itemID]: { ...item, ret } };
  return { ...state, byID };
};
