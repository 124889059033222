import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import PriceGroup from '../../PriceGroup';
import ButtonsGroup from '../../ButtonsGroup';
import Weight from '../../Weight';
import Icons from '../../../icons/IconsBar';
import ItemModal from '../../Modal';
import '../../style.scss';

const Component = ({ item, features, methods }) => {
  const [show, setShow] = useState(false);
  return (
    <Col className="px-0 px-lg-1 my-0 my-lg-1">
      <Card>
        <Icons item={item} methods={methods} />
        <div
          className="card-img-inner my-auto"
          onClick={() => setShow(true)}
          onKeyDown={() => setShow(true)}
          role="link"
          tabIndex={0}
        >
          <Card.Img
            variant="top"
            src={item.photo}
            className="my-auto"
          />
        </div>
        <ItemModal
          show={show}
          setClose={() => setShow(false)}
          item={item}
          features={features}
          methods={methods}
        />
        <Card.Body>
          <PriceGroup item={item} />
          <Card.Title className="itemName">
            {item.name}
          </Card.Title>
          <Card.Text>
            <Weight item={item} />
            <ButtonsGroup
              item={item}
              methods={methods}
            />
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
};

Component.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    photo: PropTypes.string.isRequired,
  }).isRequired,
};

export default Component;
