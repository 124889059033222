import React from 'react';
import get from 'lodash/get';
import IconNew from '../New';
import IconBonus from '../Bonus';
import IconSale from '../Sale';
import '../style.scss';

const New = ({ item }) => {
  if (!item.isNew) return null;
  return (
    <div className="tag icon-new my-1">
      <IconNew />
      <div className="icon-title">Новинка</div>
    </div>
  );
};

const hideBonus = (props) => {
  if (get(props, 'item.bonuses', 0) < 1) return true;
  if (get(props, 'features.isActiveBonuses', false) !== true) return true;
  return false;
};

const bonusTxt = (props) => {
  const bonuses = get(props, 'item.get.bonuses', 0);
  if (bonuses > 0) return `Бонусы: + ${bonuses} шт`;
  return `Бонусы: ${get(props, 'item.bonuses')} шт`;
};

const Bonus = (props) => {
  if (hideBonus(props)) return null;
  return (
    <div className="tag icon-bonus my-1">
      <IconBonus />
      <div className="icon-title">
        {bonusTxt(props)}
      </div>
    </div>
  );
};

const Deadline = ({ item }) => {
  return null;
  // if (item.returns < 1) return null;
  // return (
  //   <div className="tag icon-sale my-1">
  //     <IconSale />
  //     <div className="icon-title">ИСГ</div>
  //   </div>
  // );
};

const Component = ({ item, features }) => (
  <>
    <New item={item} features={features} />
    <Bonus item={item} features={features} />
    <Deadline item={item} features={features} />
  </>
);

export default Component;
