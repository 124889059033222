import {
  aboveZero,
  go,
  isTrue,
} from './methods-002';

const Ruller = ({ data, ok }) => ({
  check: (fn) => {
    if (ok) return Ruller({
      ok: fn(data),
      data,
    });
    return Ruller({ ok });
  },
  to: () => ok,
});

const SCREEN_CART = '/cart';

const SCREEN_RETURNS = '/cart/returns';

const SCREEN_SHIPMENTS = '/cart/shipments';

const SCREEN_BONUSES = '/cart/bonuses';

const SCREEN_CONFIRM = '/cart/confirm';

export const goConfirm = (data) => Ruller({ data, ok: true })
  .check(go(SCREEN_CONFIRM))
  .to();  

export const goBonuses = (data) => Ruller({ data, ok: true })
  .check(isTrue('features.isActiveBonuses'))
  .check(isTrue('hasBonuses'))
  .check(go(SCREEN_BONUSES))
  .to();

export const goReturns = (data) => Ruller({ data, ok: true })
  .check(isTrue('features.isActiveReturns'))
  .check(aboveZero('total.ret.cnt'))
  .check(go(SCREEN_RETURNS))
  .to();

export const goShipments = (data) => Ruller({ data, ok: true })
  .check(isTrue('hasShipments'))
  .check(go(SCREEN_SHIPMENTS))
  .to();
