import React from 'react';
import { IconContext } from 'react-icons';
import { FaRegUser } from 'react-icons/fa';
import '../style.scss';

const View = () => (
  <div className="icon icon-main">
    <IconContext.Provider value={{ size: '25px' }}>
      <div>
        <FaRegUser />
      </div>
    </IconContext.Provider>
  </div>
);

export default View;
