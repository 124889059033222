import { createAsyncThunk } from '@reduxjs/toolkit';
import request from '../../../utils/request/index';
import {
  getLines,
  getReturns,
  rejected,
} from './methods-001';

export default createAsyncThunk(
  'orders/create',
  async ({ token, bonuses, items, shipment }, { rejectWithValue }) => {
    const error = () => rejectWithValue({ status: 'error' });
    try {
      const lines = getLines(items);
      const returns = getReturns(items);
      const order = { lines, returns };
      const response = await request(token).ordersCreate({
        bonuses,
        order,
        shipment,
      });
      if (rejected(response.data)) return error();
      const { status, orderID } = response.data;
      return { status, orderID };
    } catch (err) {
      return error();
    }
  },
);
