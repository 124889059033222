import React from 'react';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';

const Component = ({ address, setTo, to }) => (
  <ListGroup.Item as="li">
    <Form.Check
      type="radio"
      label={address.address}
      id={address.id}
      name="addressList"
      checked={`${to}` === `${address.id}`}
      onChange={(event) => setTo(event.target.id)}
    />
  </ListGroup.Item>
);

export default Component;
