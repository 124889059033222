import at from 'lodash/at';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { time } from './methods-003';

dayjs.extend(isBetween);

export const exclude = (day, trip) => {
  if (`${day}` !== '0') return false;
  return true;
  // const [
  //   orderFrom,
  //   orderTo,
  // ] = at(trip, [
  //   'order_at.from',
  //   'order_at.to',
  // ]);
  // const fromTime = time(orderFrom);
  // const toTime = time(orderTo);
  // return !dayjs().isBetween(fromTime, toTime);
};

export const formatByDay = (dayNum) => {
  const num = `${dayNum}`;
  if (num === '0') return 'Cегодня — dddd';
  if (num === '1') return 'Завтра — dddd';
  return 'D MMMM — dddd';
};

export const labelByTrip = (trip) => {
  const [
    deliveryFrom,
    deliveryTo,
  ] = at(trip, [
    'delivery_at.from',
    'delivery_at.to',
  ]);
  return `С ${deliveryFrom} до ${deliveryTo}`;
};
