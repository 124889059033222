import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { IconContext } from 'react-icons';
import { RiErrorWarningFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';

const View = () => (
  <Row>
    <Card className="my-1 py-3">
      <Row>
        <Col xs={12}>
          <div className="empty wrong py-5">
            <IconContext.Provider value={{ size: '125px' }}>
              <div>
                <RiErrorWarningFill />
              </div>
            </IconContext.Provider>
          </div>
        </Col>
        <Col xs={12}>
          <p className="empty py-3 text">
            Заказ НЕ был создан!
          </p>
          <Row className="d-flex justify-content-center mb-3 text">
            <Link to="/cart" className="btn btn-primary my-2 w-50">
              Перейти в корзину, чтобы отправить заказ ещё раз
            </Link>
          </Row>
        </Col>
      </Row>
    </Card>
  </Row>
);

export default View;
