import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { IconContext } from 'react-icons';
import { FiShoppingCart } from 'react-icons/fi';

const View = () => (
  <Row>
    <Card className="my-1 py-3">
      <Row>
        <Col xs={12}>
          <div className="empty py-5">
            <IconContext.Provider value={{ size: '125px' }}>
              <div>
                <FiShoppingCart />
              </div>
            </IconContext.Provider>
          </div>
        </Col>
        <Col xs={12}>
          <div className="empty py-5 text">
            Ваша корзина пуста
            <div className="my-3">
              <Link to="/cats">
                Перейти в каталог
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </Card>
  </Row>
);

export default View;
