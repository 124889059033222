import React from 'react';
import eq from 'lodash/fp/eq';
import props from 'lodash/fp/props';
import { useSelector } from 'react-redux';
import { IconContext } from 'react-icons';
import { FiShoppingCart } from 'react-icons/fi';
import { getTotal } from '../../../reducers';
import { fetchDollarsCents } from '../../../utils';
import '../style.scss';

const View = () => {
  const total = useSelector(getTotal, eq);
  const [cnt, amount] = props(['get.cnt', 'get.amount.total'], total);
  const [dol, cen] = fetchDollarsCents(amount);
  return (
    <>
      <div className="mx-3">
        {dol}
        <small>,{cen}</small>
        {' '}
        <span>₽</span>
      </div>

      <div className="icon icon-main">
        <IconContext.Provider
          value={{ size: '25px' }}
        >
          <div>
            <FiShoppingCart />
          </div>
        </IconContext.Provider>
        {cnt > 0 && 
          <div className="bage">
            {cnt}
          </div>
        }
      </div>
    </>
  );
};

export default View;
