import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { actions as cartActions } from '../../../features/cart';
import { actions } from '../../../features/items';
import View from './View';

const Screen = () => {
  const dispatch = useDispatch();
  const decQty = useCallback((itemID) => () => dispatch(actions.decQty({ itemID })), [dispatch]);
  const incQty = useCallback((itemID) => () => dispatch(actions.incQty({ itemID })), [dispatch]);
  const putQty = useCallback((itemID) => (qty) => dispatch(actions.putQty({ itemID, qty })), [dispatch]);
  const decRet = useCallback((itemID) => () => dispatch(actions.decRet({ itemID })), [dispatch]);
  const incRet = useCallback((itemID) => () => dispatch(actions.incRet({ itemID })), [dispatch]);
  const putRet = useCallback((itemID) => (qty) => dispatch(actions.putRet({ itemID, qty })), [dispatch]);
  const methods = {
    decQty,
    incQty,
    putQty,
    decRet,
    incRet,
    putRet,
  };
  useEffect(() => dispatch(cartActions.setStep({ step: 1 })), []);
  return (<View methods={methods} />);
};

export default Screen;
