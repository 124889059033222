import reduce from 'lodash/reduce';
import dayjs from 'dayjs';
import ru from 'dayjs/locale/ru';
import {
  exclude,
  formatByDay,
  labelByTrip,
} from './methods-002';

dayjs.locale('ru');

export const labelByDay = (day) => dayjs().add(day, 'day').format(formatByDay(day));

export const tripsByDay = (shipment, day) => reduce(shipment, (out, trip, tripID) => {
  if (exclude(day, trip)) return out;
  const label = labelByTrip(trip);
  return [...out, { label, value: tripID }];
}, []);
