import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Col, Row, Button,
} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

const Component = ({ setClose, show }) => {
  if (!show) return null;
  return (
    <>
      <Modal
        show={show}
        onHide={setClose}
        centered
      >
        <Modal.Header className="show-grid p-1 pt-3">
          <Button className="btn-close" onClick={setClose}> </Button>
          <Container>
            <Row>
              <Col xs={12}>
                <Modal.Title>
                  Программа лояльности поставщика
                </Modal.Title>
              </Col>
            </Row>
          </Container>
        </Modal.Header>
        <Modal.Body className="show-grid p-1">
          <Container>
            <Row>
              <Col>
                Текст программы лояльности
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

Component.propTypes = {
  setClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default Component;
