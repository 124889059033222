import React, { useState } from 'react';
import eq from 'lodash/fp/eq';
import pick from 'lodash/pick';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row, Button } from 'react-bootstrap';
import {
  getToken,
  getTotal,
  getDelivery,
  getShipments,
  getBonusesDecInc,
  getItemsToPost,
} from '../../../../reducers';
import create from '../../../../features/orders/create';
import { fetchMy } from '../../../../features/orders/fetch';
import { actions } from '../../../../features/items';
import * as canGo from '../../../../features/cart/can-go-next-screen';
import * as nextScreen from '../../../../features/cart/get-next-screen';
import fetchCartData from '../../../../features/items/fetch-cart-data';
import Header from '../../../../components/Header';
import Stepper from '../../../../components/cart/Stepper';
import PageHeader from '../../../../components/PageHeader';
import Totals from '../../../../components/cart/Totals';
import Liability from '../../../../components/cart/Totals/Liability';
import Order from '../../../../components/cart/Totals/Order';
import Return from '../../../../components/cart/Totals/Return';
import Address from '../../../../components/cart/Totals/Address';
import DeliveryDate from '../../../../components/cart/Totals/DeliveryDate';
import Cargo from '../../../../components/cart/Totals/Cargo';
import Message from '../../../../components/cart/Totals/Message';
import BonusCredit from '../../../../components/cart/Totals/BonusCredit';
import BonusDebit from '../../../../components/cart/Totals/BonusDebit';
import {
  statusError,
  notCreated,
} from './methods-001';

const View = ({ methods }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const total = useSelector(getTotal, eq);
  const items = useSelector(getItemsToPost, eq);
  const delivery = useSelector(getDelivery, eq);
  const shipments = useSelector(getShipments, eq);
  const bonusesDecInc = useSelector(getBonusesDecInc, eq);
  const [isLoading, setLoading] = useState(false);
  const handleSubmit = async () => {
    setLoading(true);
    const when = pick(shipments, ['day', 'trip']);
    const where = pick(delivery, ['to']);
    const shipment = { when, where };
    const bonuses = { use: bonusesDecInc.dec };
    const data = {
      token,
      items,
      shipment,
      bonuses,
    };
    const resCreate = await dispatch(create(data));
    if (statusError(resCreate)) {
      setLoading(false);
      history.push('/order-create-error');
      return false;
    }
    const resOrders  = await dispatch(fetchMy({ token }));
    setLoading(false);
    if (notCreated(resCreate, resOrders)) {
      history.push('/order-create-error');
      return false;   
    }
    history.push('/order-create-success');
  };
  return (
    <>
      <Header />
      <Container className="py-4">
        <PageHeader text="Подтверждение заказа" />
        <Stepper />
        <Row>
          <Col xs={12} md={5} lg={4} className="mx-auto">
            <div className="totals p-3">
              <Liability />
              <Order />
              <Return />
              <DeliveryDate />
              <Cargo />
              <BonusCredit />
              <BonusDebit />
              <Address
                isLoaded={canGo}
              />
              <Row>
                <Col>
                  <div className="dash my-4" />
                  <Button
                    variant="primary"
                    className="w-100 my-2"
                    disabled={isLoading}
                    onClick={() => handleSubmit()}
                  >
                    Создать заказ
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default View;
