import React from 'react';
import { IconContext } from 'react-icons';
import { MdStars } from 'react-icons/md';
import '../style.scss';

const View = () => (
  <div className="icon icon-bonus">
    <IconContext.Provider value={{ size: '26px' }}>
      <div>
        <MdStars />
      </div>
    </IconContext.Provider>
    <span>Начисляются бонусы</span>
  </div>
);

export default View;
