import map from 'lodash/map';

export const emptyCat = (catID) => ({
  byCats: { [catID]: [] },
  byID: {},
});

export const formatCat = (catID, items) => ({ [catID]: map(items, 'id') });

export const notFetched = (items) => {
  if (!Array.isArray(items)) return true;
  if (items.length < 1) return true;
  return false;
};
