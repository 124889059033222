import { createAsyncThunk } from '@reduxjs/toolkit';
import request from '../../../utils/request/index';

export default createAsyncThunk(
  'items/copy-order',
  async ({ order, token }, { rejectWithValue }) => {
    try {
      const orderID = order.id;
      const response = await request(token).itemsFetchByOrderID({ orderID });
      const { items } = response.data;
      return { order, items };
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  },
);
