import isArray from 'lodash/isArray';
import get from 'lodash/get';
import reduce from 'lodash/reduce';
import union from 'lodash/union';
import find from 'lodash/find';
import min from 'lodash/fp/min';
import extend from 'lodash/fp/extend';
import pipe from 'lodash/fp/pipe';
import prop from 'lodash/fp/prop';
import props from 'lodash/fp/props';
import toString from 'lodash/fp/toString';
import flatten from 'lodash/fp/flatten';
import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect'
import { centsToDollars, toPositive } from '../../utils';
import {
  addQty,
  decQty,
  incQty,
  putQty,
  decRet,
  incRet,
  putRet,
} from './cart';
import fetchBonuses from './fetch-bonuses/fulfilled';
import copyOrder from './copy-order/fulfilled';
import fetchByCat from './fetch-by-cat/fulfilled';
import fetchCartData from './fetch-cart-data/fulfilled';
import {
  initTotal,
  prepareItem,
  prepareTotal,
  processItem,
} from './methods-001';

const initialState = {
  byID: {},
  allIDs: [],
  byCats: {},
  custByID: {},
  byCust: {},
  atCust: {},
  bonuses: {
    balance: 0,
    tos: '',
    trans: {
      all: [],
      in: [],
      out: [],
    },
    use: 0,
  },
  debts: {
    isExceeded: false,
    raw: {
      debt: 0,
      debtStale: 0,
    },
  },
  limits: {
    minOrderAmount: 0,
  },
  delivery: {
    to: undefined,
    addresses: [],
  },
  shipments: {
    day: undefined,
    trip: undefined,
    lines: [],
  },
};

const setAddress = (state, { payload }) => {
  const { to } = payload;
  const delivery = {
    ...state.delivery,
    to: toPositive(to),
  };
  return { ...state, delivery };
};

const setShipment = (state, { payload }) => {
  const { day, trip } = payload;
  const shipments = {
    ...state.shipments,
    day,
    trip
  };
  return { ...state, shipments };
};

export const setBonusesUse = (state, data) => pipe(
  prop('payload.use'),
  toPositive,
  (use) => ({ ...state.bonuses, use }),
  (bonuses) => ({ bonuses }),
  extend({ ...state }),
)(data);

const itemsSlice = createSlice({
  name: 'items',
  initialState,
  reducers: {
    clearCart: () => initialState,
    addQty,
    decQty,
    incQty,
    putQty,
    decRet,
    incRet,
    putRet,
    setAddress,
    setBonusesUse,
    setShipment,
  },
  extraReducers: {
    'auth/exit/pending': () => initialState,
    'items/fetch-by-cat/fulfilled': fetchByCat,
    'items/fetch-cart-data/fulfilled': fetchCartData,
    'items/copy-order/fulfilled': copyOrder,
    'items/fetch-bonuses/fulfilled': fetchBonuses,
  },
});

const bonuses = prop('bonuses');

const cust = (state) => state.byCust;

const items = (state) => state.byID;

export const bonusesTrans = pipe(
  prop('bonuses.trans'),
);

export const bonusesTos = pipe(
  prop('bonuses.tos'),
  toString,
);

export const bonusesBalance = pipe(
  prop('bonuses.balance'),
  toPositive,
);

export const hasBonuses = pipe(
  bonusesBalance,
  (balance) => balance > 0,
);

export const delivery = (state) => state.delivery;

export const shipments = (state) => state.shipments;

export const hasShipments = (state) => {
  const lines = get(state, 'shipments.lines', []);
  if (isArray(lines)) return lines.length > 0;
  return false;
};

const prepareDebt = pipe(
  toPositive,
  centsToDollars,
);

export const getDebts = pipe(
  props([
    'debts.isExceeded',
    'debts.raw.debt',
    'debts.raw.debtStale',
  ]),
  ([isExceeded, debt, debtStale]) => [
    !!isExceeded,
    prepareDebt(debt),
    prepareDebt(debtStale),
  ],
  flatten,
  ([isExceeded, debt, debtStale]) => ({
    isExceeded,
    debt,
    debtStale,
  }),
);

export const returns = ({ delivery }) => {
  const {addresses, to} = delivery;
  const limit = get(
    find(addresses, { id: to }),
    'returnsLimit.amount',
    0,
  );
  const amount = centsToDollars(limit);
  return { limit, amount };
};

export const limits = (state) => {
  const limit = get(state, 'limits.minOrderAmount', 0);
  const amount = centsToDollars(limit);
  return { minOrderAmount: { amount, limit } };
};

export const item = createSelector(
  items,
  (items) => (itemID) => prepareItem(items[itemID]),
);

export const itemIDsAtCart = createSelector(
  items,
  (items) => reduce(items, (out, item, itemID) => {
    const qty = get(item, 'get.qty', 0);
    const qtyCust = get(item, 'qtyCust', 0);
    const exclude = qty < 1 && qtyCust < 1;
    if (exclude) return out;
    return [ ...out, itemID ];
  }, []),
);

export const itemIDsByCat = createSelector(
  (state) => state.byCats,
  (byCats) => (catID) => get(byCats, catID, []),
);

export const itemIDsToReturn = createSelector(
  items,
  (items) => reduce(items, (out, item, itemID) => {
    const qty = get(item, 'ret.qty', 0);
    if (qty < 1) return out;
    return [ ...out, itemID ];
  }, []),
);

export const itemsIDsCartReturnes = createSelector(
  itemIDsAtCart,
  itemIDsToReturn,
  (atCartIDs, toReturnsIDs) => union(atCartIDs, toReturnsIDs),
);

export const itemsToPost = createSelector(
  itemsIDsCartReturnes,
  items,
  (itemIDs, items) => itemIDs.map((itemID) => prepareItem(items[itemID])),
);

export const total = createSelector(
  itemsIDsCartReturnes,
  item,
  (itemIDs, fetchItem) => prepareTotal(
    itemIDs.reduce((out, itemID) => {
      const item = fetchItem(itemID);
      const get = processItem(item.get, out.get, 0);
      const ret = processItem(item.ret, out.ret, item.returns);
      return { get, ret };
    }, initTotal),
  ),
);

export const nextScreenData = (state) => ({
  hasBonuses: hasBonuses(state),
  hasShipments: hasShipments(state),
  total: total(state),
});

const bonusesDec = pipe(
  prop('bonuses.use'),
  toPositive,
);

const bonusesInс = pipe(
  total,
  prop('get.bonuses'),
  toPositive,
);

export const bonusesDecInc = (state) => {
  const dec = bonusesDec(state);
  const inc = bonusesInс(state);
  return { dec, inc };
};

const centsToDol = (cents) => cents / 100;

const orderTotal = pipe(
  total,
  prop('get.amount.total'),
  toPositive,
  centsToDol,
  toPositive,
);

export const bonusesMax = pipe(
  (state) => [
    bonusesBalance(state),
    orderTotal(state),
  ],
  min,
);

export const { actions } = itemsSlice;

export default itemsSlice.reducer;
