import { createSlice } from '@reduxjs/toolkit';
import head from 'lodash/fp/head';
import prop from 'lodash/fp/prop';
import pipe from 'lodash/fp/pipe';
import fetch from './fetch/fulfilled';

const initialState = {
  byID: {},
  allIDs: [],
};

const catsSlice = createSlice({
  name: 'cats',
  initialState,
  reducers: {},
  extraReducers: {
    'auth/exit/pending': () => initialState,
    'cats/fetch/fulfilled': fetch,
  },
});

export const cat = (state, catID) => state.byID[catID];

export const cats = (state) => state.allIDs.map((catID) => cat(state, catID));

export const firstCatID = pipe(
  prop('allIDs'),
  head,
);

export default catsSlice.reducer;
