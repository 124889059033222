import React from 'react';
import eq from 'lodash/fp/eq';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { getBonusesDecInc, getBonusesMax } from '../../../reducers';
import { actions } from '../../../features/items';
import IconDown from '../../icons/ArrowDown';
import Slider from '../../Slider';

const View = () => {
  const dispatch = useDispatch();
  const { dec } = useSelector(getBonusesDecInc, eq);
  const max = useSelector(getBonusesMax, eq);
  const setDec = (use) => dispatch(actions.setBonusesUse({ use }));;
  return (
    <Row>
      <Card className="py-3">
        <Row>
          <Col>
            <div className="title">
              Списать:
            </div>
          </Col>
          <Col>
            <div className="title">
              {dec}
            </div>
          </Col>
          <Col xs={1}>
            <div className="float-end">
              <IconDown />
            </div>
          </Col>
        </Row>
        <Row className="my-5">
          <Col>
            <Slider
              max={max}
              setDec={setDec}
            />
          </Col>
        </Row>
      </Card>
    </Row>
  );
};

export default View;
