import React from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import Cats from '../../screens/Cats';
import Items from '../../screens/Items';
import Cart from '../../screens/cart/Cart';
import CartBlocked from '../../screens/cart/Blocked';
import CartBonuses from '../../screens/cart/Bonuses';
import CartConfirm from '../../screens/cart/Confirm';
import CartEmpty from '../../screens/cart/Empty';
import CartError from '../../screens/cart/Error';
import CartReturns from '../../screens/cart/Returns';
import CartShipments from '../../screens/cart/Shipments';
import CartSuccess from '../../screens/cart/Success';
import Orders from '../../screens/Orders';
import BonusTrans from '../../screens/BonusTrans';

const Component = () => (
  <Switch>
    <Route path="/cats/:catID" component={Items} />
    <Route path="/cats" component={Cats} exact />
    <Route path="/cart/blocked" component={CartBlocked} exact />
    <Route path="/cart/bonuses" component={CartBonuses} exact />
    <Route path="/cart/confirm" component={CartConfirm} exact />
    <Route path="/cart/empty" component={CartEmpty} exact />
    <Route path="/cart/returns" component={CartReturns} exact />
    <Route path="/cart/shipments" component={CartShipments} exact />
    <Route path="/cart" component={Cart} exact />
    <Route path="/order-create-error" component={CartError} exact />
    <Route path="/order-create-success" component={CartSuccess} exact />
    <Route path="/orders" component={Orders} exact />
    <Route path="/bonuses" component={BonusTrans} exact />
    <Route path="/" exact>
      <Redirect to="/cats" />
    </Route>
  </Switch>
);

export default Component;
