import eq from 'lodash/fp/eq';
import includes from 'lodash/fp/includes';
import find from 'lodash/fp/find';
import pipe from 'lodash/fp/pipe';
import prop from 'lodash/fp/prop';
import toArray from 'lodash/fp/toArray';

export const statusError = pipe(
  prop('payload.status'),
  eq('ok'),
  (bool) => !bool,
);

const getIDs = pipe(
  prop('payload.orders.allIDs'),
  toArray,
);

export const notCreated = (order, orders) => {
  const orderID = prop('payload.orderID', order);
  const orderIDS = getIDs(orders);
  return orderIDS.findIndex((id) => `${id}` === `${orderID}`) === -1;
};
