import React, { useState } from 'react';
import { Redirect } from "react-router";
import {Form, Button } from 'react-bootstrap';
import { Formik, ErrorMessage } from 'formik';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import phone from '../../../../features/auth/phone';
import { isCode } from '../../../../reducers';
import './style.scss';

const ErrorMsg = ({ name }) => (
  <ErrorMessage
    name={name}
    render={(msg) => <Form.Text className="text-danger">{msg}</Form.Text>}
  />
);

const View = () => {
  const toCode = useSelector(isCode);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (values, { setErrors }) => {
    setLoading(true);
    const { payload: { errors } } = await dispatch(phone({ ...values }));
    setLoading(false);
    if (errors) return setErrors(errors);
  };
  if (toCode) return <Redirect to="/code" />;
  return (
    <div className="pt-5">
      <div className="brand-logo mb-5"></div>
      <h2 className="text-center">SNARTA вход</h2>
      <div className="note text-center mb-2">Пожалуйста, введите номер телефона</div>
      <Formik
        className="pt-2"
        initialValues={{ phone: '' }}
        onSubmit={handleSubmit}
      >
        {({ handleChange, handleSubmit, values }) => (
          <>
            <Form.Group className="mb-3" controlId="formBasicPhone">
              <Form.Label className="auth-lable">
                Номер телефона
              </Form.Label>
              <NumberFormat
                className="form-control"
                format="+7 (###) ###-##-##"
                mask="_"
                placeholder="+7 (___) ___-__-__"
                value={values.phone}
                onChange={handleChange('phone')}
                onKeyUp={({ keyCode }) => {
                  if (keyCode !== 13) return false;
                  handleSubmit();
                  return true;
                }}
              />
              <ErrorMsg name="phone" />
            </Form.Group>
            <div className="d-grid gap-2">
              <Button
                variant="primary"
                type="submit"
                size="lg"
                disabled={loading}
                onClick={handleSubmit}
              >
                Войти в Snarta
              </Button>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};

export default View;
