import React from 'react';
import eq from 'lodash/fp/eq';
import props from 'lodash/fp/props';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { getTotal } from '../../../../reducers';
import { fetchDollarsCents } from '../../../../utils';

const View = () => {
  const total = useSelector(getTotal, eq);
  const [
    cnt,
    amount,
  ] = props([
    'get.cnt',
    'get.amount.total',
  ], total);
  const [dol, cen] = fetchDollarsCents(amount);
  return (
    <Row className="my-1">
      <Col>
        <div className="title">
          Всего по заказу:
        </div>
        <div className="note">
          количество товаров {cnt} шт.
        </div>
        <div className="dash my-3 w-50"> </div>
      </Col>
      <Col>
        <div className="title float-end">
          {dol}
          <small>,{cen}</small>
          {' '}
          <span>₽</span>
        </div>
      </Col>
    </Row>
  );
};

export default View;
