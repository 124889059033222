import React, { useState } from 'react';
import eq from 'lodash/fp/eq';
import prop from 'lodash/fp/prop';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import ListGroup from 'react-bootstrap/ListGroup';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCats } from '../../reducers';
import './style.scss';

const View = (props) => {
  const cats = useSelector(getCats, eq);
  const catID = prop('match.params.catID', props);
  const [expanded, setExpanded] = useState(true);
  return (
    <Navbar 
      expand="md" 
      className="flex-md-column w-100 py-0"
      expanded={expanded}>
      <Navbar.Toggle 
        onClick={() => setExpanded(!expanded)}
        aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className="w-100">
        <Nav className="mr-auto my-2 flex-column w-100 sidebar">
          <ListGroup as="ul">
            {cats.map(({ id, name }) => (
              <ListGroup.Item
                as="li"
                action
                key={`${id}`}
                active={`${id}` === `${catID}`}
              >
                <Link
                  key={`${id}`}
                  active={`${id}` === `${catID}`}
                  to={`/cats/${id}`}
                  onClick={() => setExpanded(false)}>
                  {name}
                </Link>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default View;
