import { createAsyncThunk } from '@reduxjs/toolkit';
import request from '../../../utils/request/index';

export default createAsyncThunk(
  'auth/phone',
  async ({ phone }, { rejectWithValue }) => {
    try {
      const { data } = await request().phone({ phone });
      return data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  },
);