import React, { useState } from 'react';
import prop from 'lodash/fp/prop';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import ListGroup from 'react-bootstrap/ListGroup';
import { getToken } from '../../../reducers'
import exit from '../../../features/auth/exit'
import IconExit from '../../icons/Exit';
import '../style.scss';
import Exit from './Exit';

const View = (props) => {
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const [show, setShow] = useState(false);
  const handleExit = async () => dispatch(exit({ token }));
  const path = prop('match.path', props);
  const [expanded, setExpanded] = useState(true);
  return (
    <>
      <Navbar 
        expand="md" 
        className="flex-md-column w-100 py-0"
        expanded={expanded}
      >
        <Navbar.Toggle 
          onClick={() => setExpanded(!expanded)}
          aria-controls="basic-navbar-nav"
        />
        <Navbar.Collapse id="basic-navbar-nav" className="w-100">
          <Nav className="mr-auto my-2 flex-column w-100 sidebar">
            <ListGroup as="ul">
              <ListGroup.Item as="li" active={path === '/orders'}>
                <Link
                  onClick={() => setExpanded(false)} 
                  to="/orders">
                  История заказов
                </Link>
              </ListGroup.Item>
              <ListGroup.Item as="li" active={path === '/bonuses'}>
                <Link
                  onClick={() => setExpanded(false)} 
                  to="/bonuses">
                  Бонусы
                </Link>
              </ListGroup.Item>
            </ListGroup>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Exit
        show={show}
        setClose={() => setShow(false)}
        handleExit={handleExit}
      />
    </>
  );
};

export default View;
