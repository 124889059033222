import React from 'react';
import { IconContext } from 'react-icons';
import { IoMdRedo } from 'react-icons/io';
import '../style.scss';

const View = () => (
  <div className="icon">
    <div className="icon-round-bg icon-return">
      <IconContext.Provider value={{ size: '20px' }}>
        <div>
          <IoMdRedo />
        </div>
      </IconContext.Provider>
    </div>
    <span>Возможен возврат</span>
  </div>
);

export default View;
