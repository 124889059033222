import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const View = ({ show, setClose, handleExit }) => {
  if (!show) return null;
  return (
    <Modal show onHide={setClose}>
      <Modal.Header className="show-grid p-1 pt-3">
        <Button className="btn-close" onClick={setClose} />
        <Container>
          <Row>
            <Col xs={12}>
              <Modal.Title>
                Выйти из Snarta?
              </Modal.Title>
            </Col>
          </Row>
        </Container>
      </Modal.Header>
      <Modal.Body>
        Точно хотите выйти из Snarta? После этого не сможете изучать каталог и создавать новые заказы
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleExit}>Да, выйти</Button>
        <Button variant="primary" onClick={setClose}>Нет, ещё поработаем</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default View;
