import React from 'react';
import eq from 'lodash/fp/eq';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { IconContext } from 'react-icons';
import { RiErrorWarningFill } from 'react-icons/ri';
import { getDebts } from '../../../../reducers';

const View = () => {
  const { debt, debtStale } = useSelector(getDebts, eq);
  return (
    <Row>
      <Card className="my-1 py-3">
        <Row>
          <Col xs={12}>
            <div className="empty pt-5 text h1">
              Невозможно создать заказ
            </div>
          </Col>
          <Col xs={12}>
            <div className="empty wrong py-5">
              <IconContext.Provider value={{ size: '125px' }}>
                <div>
                  <RiErrorWarningFill />
                </div>
              </IconContext.Provider>
            </div>
          </Col>
          <Col xs={12}>
            <div className="empty pb-5 text">
              <div className="my-3">
                Размер просроченной задолженности превысил допустимое значение.
              </div>
              <div className="my-3">
                Общая задолженность: {` ${debt} просроченная: ${debtStale} руб.`}
              </div>
              <div className="my-3">
                Для решения вопроса, пожалуйста, позвоните в отдел по работе с клиентами.
              </div>
            </div>
          </Col>
        </Row>
      </Card>
    </Row>
  );
};

export default View;
