import {
  addCustToCart,
  getBonuses,
  getDelivery,
  getShipments,
} from './methods-001';

export default (state, { payload }) => {
  const {
    byCusts,
    byItems,
    customers,
    items,
    debt,
    debtStale,
    isDebtStaleLimitExceeded,
    minOrderAmount,
  } = payload;
  const byID = { ...state.byID, ...items };
  const allIDs = Object.keys(byID);
  const atCart = addCustToCart(state, byItems);
  const debts = {
    isExceeded: isDebtStaleLimitExceeded,
    raw: { debt, debtStale },
  };
  const limits = {
    minOrderAmount,
  };
  const bonuses = getBonuses(payload);
  const delivery = getDelivery(state, payload);
  const shipments = getShipments(payload);
  return {
    ...state,
    ...atCart,
    byID,
    allIDs,
    bonuses,
    debts,
    limits,
    shipments,
    delivery,
    custByID: customers,
    byCust: byCusts,
    atCust: byItems,
  };
};
