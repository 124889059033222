import React, { useEffect, useCallback } from 'react';
import eq from 'lodash/fp/eq';
import prop from 'lodash/fp/prop';
import Row from 'react-bootstrap/Row';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../features/items';
import fetchItems from '../../../features/items/fetch-by-cat';
import {
  getToken,
  getCat,
  getItemIDsByCat,
  getFeaturesStatus,
} from '../../../reducers';
import Item from '../Item';

const View = (props) => {
  const catID = prop('match.params.catID', props);
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const cat = useSelector((state) => getCat(state, catID), eq);
  const itemIDs = useSelector((state) => getItemIDsByCat(state, catID), eq);
  const features = useSelector(getFeaturesStatus, eq);
  useEffect(() => {
    const load = async () => dispatch(fetchItems({ token, catID }));
    load();
  }, [catID]);
  const decQty = useCallback((itemID) => () => dispatch(actions.decQty({ itemID })), [dispatch]);
  const incQty = useCallback((itemID) => () => dispatch(actions.incQty({ itemID })), [dispatch]);
  const putQty = useCallback((itemID) => (qty) => dispatch(actions.putQty({ itemID, qty })), [dispatch]);
  const decRet = useCallback((itemID) => () => dispatch(actions.decRet({ itemID })), [dispatch]);
  const incRet = useCallback((itemID) => () => dispatch(actions.incRet({ itemID })), [dispatch]);
  const putRet = useCallback((itemID) => (qty) => dispatch(actions.putRet({ itemID, qty })), [dispatch]);
  const methods = {
    decQty,
    incQty,
    putQty,
    decRet,
    incRet,
    putRet,
  };
  return (
    <div>
      <h2>
        {cat.name}
      </h2>
      <Row xs={2} md={3} lg={4}>
        {itemIDs.map((itemID) => (
          <Item
            itemID={itemID}
            features={features}
            methods={methods}
            key={`${itemID}`}
          />
        ))}
      </Row>
    </div>
  );
};

export default View;
