import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import {
  labelByDay,
  tripsByDay,
} from './methods-001';

export const format = (shipments) => reduce(shipments, (out, shipment, day) => {
  const trips = tripsByDay(shipment, day);
  if (isEmpty(trips)) return out;
  const label = labelByDay(day);
  return [...out, {
    trips,
    label,
    value: day,
  }];
}, []);

export default format;
