import React from 'react';
import eq from 'lodash/fp/eq';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import {
  getBonusesDecInc,
  getFeaturesStatus,
} from '../../../../reducers';

const View = () => {
  const { dec } = useSelector(getBonusesDecInc, eq);
  const features = useSelector(getFeaturesStatus, eq);
  if (!features.isActiveBonuses) return null;
  if (dec < 1) return null;
  return (
    <Row className="my-1">
      <Col>
        <div className="title">
          Бонусы к списанию:
        </div>
        <div className="dash my-3 w-50"> </div>
      </Col>
      <Col>
        <div className="title float-end">
          {dec}
        </div>
      </Col>
    </Row>
  );
};

export default View;
