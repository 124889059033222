import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';

const Layout = ({ Content }) => (
  <div>
    <Container fluid="sm" className="pt-5">
      <Row className="justify-content-center">
        <Col xs sm="8" md="7" lg="4" className="p-4 pt-0 bg-white">
          {Content}
        </Col>
      </Row>
    </Container>
  </div>
);

Layout.propTypes = {
  Content: PropTypes.shape({}).isRequired,
};

export default Layout;
