import React from 'react';
import { useDispatch } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ModalFooter from 'react-bootstrap/ModalFooter';
import Spinner from 'react-bootstrap/Spinner';
import ListGroup from 'react-bootstrap/ListGroup';
import ListItem from './List';

const CopyButton = ({ handleCopy, copyStatus, copyStatuses }) => {
  if (copyStatus === copyStatuses.progress) return (
    <Button variant="primary" disabled>
      <Spinner
        animation="border"
        role="status"
        variant="light"
        title="Копирую заказ..."
        size="sm"
        >
      </Spinner>
      Копирую заказ...
    </Button>
  );
  if (copyStatus === copyStatuses.done) return (
    <Button variant="primary" onClick={handleCopy}>
      Заказ скопирован!
    </Button>
  );
  return (
    <Button variant="primary" onClick={handleCopy}>
      Скопировать заказ
    </Button>
  );
};

const Component = ({ order, setClose, show, handleCopy, copyStatus, copyStatuses }) => {
  if (!show) return null;
  return (
    <Modal
      show={show}
      onHide={setClose}
      centered
    >
      <Modal.Header className="show-grid p-1 pt-3">
        <Button className="btn-close" onClick={setClose} />
        <Container>
          <Row>
            <Col xs={12}>
              <Modal.Title>
                Заказ №
                {order.id}
                {' '}
                от
                {' '}
                {order.created}
              </Modal.Title>
            </Col>
          </Row>
        </Container>
      </Modal.Header>
      <Modal.Body className="show-grid py-4 px-0 history">
        <Container>
          <Row>
            <Col>
              <ListGroup as="ul">
                {order.lines.map((item) => <ListItem item={item} key={`${item.id}`} />)}
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <ModalFooter className="shadow bg-light">
        <Container>
          <Row>
            <Col>
              <div className="title">
                ИТОГО:
              </div>
            </Col>
            <Col>
              <div className="float-end title">
                {`${order.total.amount} `}
                <span>₽</span>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <div className="float-end">
                <Button
                  variant="secondary"
                  onClick={setClose}
                >
                  Закрыть
                </Button>
                {' '}
                <CopyButton
                  handleCopy={handleCopy}
                  copyStatus={copyStatus}
                  copyStatuses={copyStatuses}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </ModalFooter>
    </Modal>
  );
};

export default Component;
