import pipe from 'lodash/fp/pipe';
import prop from 'lodash/fp/prop';
import props from 'lodash/fp/props';

const Solver = ({ data, out }) => ({
  check: (fn) => {
    if (!out.can) return Solver({ out });
    const [can, why] = fn(data);
    return Solver({ data, out: { can, why } });
  },
  fold: () => [out.can, out.why],
});

const cartDataLoaded = pipe(
  prop('cart.isLoaded'),
  (is) => {
    if (is) return [true];
    return [false, 'Обновление итогов корзины...'];
  },
);

const orderAmountAboveZero = pipe(
  prop('totals.get.amount.base'),
  (orderAmount) => {
    if (orderAmount > 0) return [true];
    return [false, 'В корзине нет товаров']
  },
);

const orderAmountAboveLimit = pipe(
  props([
    'totals.get.amount.base',
    'limits.minOrderAmount.limit',
    'limits.minOrderAmount.amount',
  ]),
  ([orderAmount, limit, limitStr]) => {
    if (limit < 1) return [true];
    if (limit <= orderAmount) return [true];
    return [false, `Минимальный заказ ${limitStr} руб`];
  },
);

const isDebtsExceeded = pipe(
  prop('debts.isExceeded'),
  (is) => {
    if (is) return [false, 'Превышен лимит дебиторской задолженности'];
    return [true];
  },
);

const returnsExceedeLimit = pipe(
  props([
    'returns.limit',
    'totals.ret.amount.total',
    'totals.ret.ignored.total',
  ]),
  ([limit, amount, ignored]) => {
    const diff = limit - amount - ignored;
    if (diff >= 0) return [true];
    return [false, 'Превышен лимит возврата']
  },
);

const shipmentsFilled = pipe(
  props([
    'shipments.lines.length',
    'shipments.day',
    'shipments.trip',
  ]),
  ([lines, day, trip]) => {
    if (lines < 1) return [true];
    if (day >= 0 && trip >=0) return [true];
    return [false, 'Выберите день и рейс доставки']
  },
);

const out = { can: true, why: '' };

export const cart = (data) => Solver({ data, out })
  .check(cartDataLoaded)
  .check(orderAmountAboveZero)
  .check(orderAmountAboveLimit)
  .check(isDebtsExceeded)
  .fold();

export const returns = (data) => Solver({ data, out })
  .check(returnsExceedeLimit)
  .fold();

export const shipments = (data) => Solver({ data, out })
  .check(shipmentsFilled)
  .fold();

export const bonuses = (data) => Solver({ data, out })
  .fold();
