import React, { useEffect } from 'react';
import isNil from 'lodash/fp/isNil';
import eq from 'lodash/fp/eq';
import { Redirect } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import fetchCats from '../../features/cats/fetch';
import fetchFeatures from '../../features/features/thunks/fetch';
import {
  getToken,
  getCats,
  getFeatures,
  getFirstCatID,
} from '../../reducers';

const Screen = () => {
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const cats = useSelector(getCats, eq);
  const catID = useSelector(getFirstCatID);
  const storedFeatures = useSelector(getFeatures, eq);
  useEffect(() => {
    const load = async () => dispatch(fetchCats({ token }));
    load();
  }, []);
  useEffect(() => {
    const load = async () => dispatch(fetchFeatures({ token, storedFeatures }));
    load();
  }, []);
  if (isNil(catID)) return null;
  return <Redirect to={`/cats/${catID}`} />;
};

export default Screen;
