import { centsToDollars } from '../../utils';

export const incCount = (qty, out) => {
  if (qty < 1) return out;
  return (out + 1);
};

export const incIgnored = (item, out, returns) => {
  if (returns !== 2) return out.ignored;
  const total = item.amount.total + out.ignored.total;
  return { total };
};

export const prepareTotalAmount = (amount) => ({
  ...amount,
  str: centsToDollars(amount.total),
});
