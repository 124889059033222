import extend from 'lodash/fp/extend';
import prop from 'lodash/fp/prop';
import pipe from 'lodash/fp/pipe';
import toArray from 'lodash/fp/toArray';
import isEqual from 'lodash/fp/isEqual';
import reduce from 'lodash/fp/reduce';
import toString from 'lodash/fp/toString';
import {toPositive} from '../../../../utils';

const inititalTrans = {
  all: [],
  in: [],
  out: [],
};

const checkType = (type, line) => pipe(
  prop('type'),
  isEqual(type),
)(line);

const put = (type, arr, line) => {
  if (checkType(type, line)) return [...arr, line];
  return [...arr];
};

const putIn = (arr, line) => put('in', arr, line);

const putOut = (arr, line) => put('out', arr, line);

const prepare = (out, line) => ({
  all: [...out.all, line],
  in: putIn(out.in, line),
  out: putOut(out.out, line),
});

const getBalace = pipe(
  prop('payload.balance'),
  toPositive,
);

const getTrans = pipe(
  prop('payload.trans'),
  toArray,
  reduce(prepare, inititalTrans)
);

const getTos = pipe(
  prop('payload.tos'),
  toString,
);

export default (state, data) => {
  const balance = getBalace(data);
  const trans = getTrans(data);
  const tos = getTos(data);
  const bonuses = {
    ...state.bonuses,
    balance,
    trans,
    tos,
  };
  return {
    ...state,
    bonuses,
  };
};
