import getVal from 'lodash/get';
import {
  calcBonuses,
  initUnit,
} from '../../utils/item';
import { centsToDollars } from '../../utils';
import {
  incCount,
  incIgnored,
  prepareTotalAmount,
} from './methods-002';

const initTotalSlice = {
  amount: {
    base: 0,
    disc: 0,
    corr: 0,
    total: 0,
    str: '0.00',
  },
  bonuses: 0,
  cnt: 0,
  ignored: {
    total: 0,
  },
  qty: 0,
};

export const prepareItem = (item) => {
  const qtyCust = getVal(item, 'qtyCust', 0);
  const cartUnit = initUnit(item, item.get.qty);
  const custUnit = initUnit(item, qtyCust);
  const bonuses = calcBonuses(item);
  const get = {
    ...item.get,
    bonuses,
  };
  return {
    ...item,
    get,
    qtyCust,
    cartUnit,
    custUnit,
  };
};

export const prepareTotal = (out) => {
  const get = { ...out.get, amount: prepareTotalAmount(out.get.amount) };
  const ret = { ...out.ret, amount: prepareTotalAmount(out.ret.amount) };
  return { get, ret };
};

export const processItem = (item, out, returns) => {
  const cnt = incCount(item.qty, out.cnt);
  const qty = item.qty + out.qty;
  const base = item.amount.base + out.amount.base;
  const disc = item.amount.disc + out.amount.disc;
  const corr = item.amount.corr + out.amount.corr;
  const total = item.amount.total + out.amount.total;
  const amount = { base, disc, corr, total };
  const ignored = incIgnored(item, out, returns);
  const bonuses = out.bonuses + getVal(item, 'bonuses', 0);
  return {
    amount,
    bonuses,
    cnt,
    ignored,
    qty,
  };
};

export const initTotal = {
  get: { ...initTotalSlice },
  ret: { ...initTotalSlice },
};
