import reduce from 'lodash/reduce';
import { formatShipment } from './methods-001';

export const format = (orders) => reduce(orders, (out, order, orderID) => ({
  ...out,
  [orderID]: {
    ...order,
    shipment: formatShipment(order),
  },
}), {});

export default format;
