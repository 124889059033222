import React, { useEffect, useState } from 'react';
import eq from 'lodash/fp/eq';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Balance from '../../../components/Bonus/Balance';
import IconUp from '../../../components/icons/ArrowUp';
import IconDown from '../../../components/icons/ArrowDown';
import { IconContext } from 'react-icons';
import { GiPiggyBank } from 'react-icons/gi';

import { getToken, getBonusesTrans } from '../../../reducers'
import fetchBonuses from '../../../features/items/fetch-bonuses';
import Empty from '../Empty';

const Icon = ({ item }) => {
  if (`${item.type}` === 'in') return <IconUp />;
  return <IconDown />
};

const TransView = ({ item }) => (
  <>
    <li className="mb-3">
      <Row>
        <Col xs={1}>
          <Icon item={item} />
        </Col>
        <Col>
          <div className="term note">
            Заказ №
          </div>
          <div className="title">
            {item.sourceID}
          </div>
        </Col>
        <Col>
          <div className="term note">
            от
          </div>
          <div className="title">
            {item.created}
          </div>
        </Col>
        <Col xs={3}>
          <div className="title float-end">
            {item.amount}
          </div>
        </Col>
      </Row>
    </li>
  </>
);

const View = () => {
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const trans = useSelector(getBonusesTrans, eq);
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await dispatch(fetchBonuses({ token }));
      setLoading(false);
    };
    load();
  }, []);
  if (isLoading) return null;
  if (trans.length < 1) return <Empty />;
  console.log(trans);
  return (
    <>
      <h2>История начисления бонусных баллов</h2>
      <Balance />
      <Row className="mt-3">
        <Tabs defaultActiveKey="in">
          <Tab eventKey="in" title="Начислено">
            <ul className="my-4">
              {trans.in.map((item) => <TransView item={item} key={`${item.sourceID}`} />)}
            </ul>
          </Tab>
          <Tab eventKey="out" title="Списано">
            <ul className="my-4">
              {trans.out.map((item) => <TransView item={item} key={`${item.sourceID}`} />)}
            </ul>
          </Tab>
          <Tab eventKey="all" title="Всего">
            <ul className="my-4">
              {trans.all.map((item) => <TransView item={item} key={`${item.sourceID}`} />)}
            </ul>
          </Tab>
        </Tabs>
      </Row>
    </>
  );
};

export default View;
