import pipe from 'lodash/fp/pipe';
import split from 'lodash/fp/split';
import toInteger from 'lodash/fp/toInteger';
import { centsToDollars } from './methods-001';

export { centsToDollars } from './methods-001';

export const fetchDollarsCents = pipe(
  toInteger,
  centsToDollars,
  split('.'),
);
