import { createAsyncThunk } from '@reduxjs/toolkit';
import request from '../../../utils/request/index';

export default createAsyncThunk(
  'auth/code',
  async ({ token, code }, { rejectWithValue }) => {
    try {
      const { data } = await request(token).code({ code });
      return data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  },
);
