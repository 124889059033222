import React, { useEffect, useState } from 'react';
import eq from 'lodash/fp/eq';
import find from 'lodash/find';
import isArray from 'lodash/isArray';
import toInteger from 'lodash/toInteger';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import {
  Card,
  Col,
  Container,
  Row,
} from 'react-bootstrap';
import Select from 'react-select';
import {
  getShipments,
  getNextScreenData,
  getCanGoNextCartScreenData,
} from '../../../../reducers';
import * as canGo from '../../../../features/cart/can-go-next-screen';
import * as nextScreen from '../../../../features/cart/get-next-screen';
import fetchCartData from '../../../../features/items/fetch-cart-data';
import { actions } from '../../../../features/items/';
import Header from '../../../../components/Header';
import Stepper from '../../../../components/cart/Stepper';
import Totals from '../../../../components/cart/Totals';
import PageHeader from '../../../../components/PageHeader';
import Item from '../Item';

export const getOneTripValue = (trips) => {
  if (!isArray(trips)) return false;
  const [{value}] = trips;
  return toInteger(value);
};

const View = ({ methods }) => {
  const dispatch = useDispatch();
  const shipments = useSelector(getShipments, eq);
  const canGoNextCartScreenData = useSelector(getCanGoNextCartScreenData, eq);
  const [can, why] = canGo.shipments(canGoNextCartScreenData);
  const nextScreenData = useSelector(getNextScreenData, eq);
  const where = nextScreen.shipments(nextScreenData);
  const [day, setDay] = useState(null);
  const [trip, setTrip] = useState(null);
  const [trips, setTrips] = useState(null);
  const handleTrip = (nextTrip) => {
    setTrip(nextTrip);
    dispatch(actions.setShipment({
      day: day.value,
      trip: nextTrip.value,
    }));
  };
  const handleDay = (nextDay) => {
    setDay(() => nextDay);
    setTrips(nextDay.trips);
    setTrip(null);
    dispatch(actions.setShipment({
      day: nextDay.value,
      trip: undefined,
    }));
  };
  return (
    <>
      <Header />
      <Container className="py-4">
        <PageHeader text="Дата и время доставки" />
        <Stepper />
        <Row>
          <Col xs={12} md={7} lg={8}>
            <Row xs={1}>
              <Col>
                <Row className="my-2">
                  <Card className="py-3">
                    <Row>
                      <Col>
                        <div className="title">День доставки:</div>
                        <div className="note">Выберите, пожалуйста, день доставки</div>
                      </Col>
                      <Col>
                        <Select
                          placeholder="день доставки"
                          options={shipments.lines}
                          onChange={handleDay}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Row>
                <Row className="my-2">
                  <Card className="py-3">
                    <Row>
                      <Col>
                        <div className="title">Рейс доставки:</div>
                        <div className="note">Выберите, пожалуйста, рейс доставки</div>
                      </Col>
                      <Col>
                        <Select
                          placeholder="рейс доставки"
                          isDisabled={!trips}
                          options={trips}
                          value={trip}
                          onChange={handleTrip}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={5} lg={4}>
            <Totals
              canGo={can}
              ifNotWhy={why}
              where={where}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default View;
