import at from 'lodash/at';
import reduce from 'lodash/reduce';
import formatItems from '../../../../utils/format/items';
import { merge } from '../../../../utils/item';
import { addQty } from '../../cart';
import { notFetched, noItems } from './methods-002';

export const mergeItems = (state, items) => reduce(items.byID, (out, nextItem, itemID) => {
  const [prevItem] = at(state, `byID.${itemID}`);
  const item = merge(prevItem, nextItem);
  return { ...out, [itemID]: item };
}, state.byID);

export const prepare = (payload) => {
  const [items] = at(payload, ['items']);  
  if (notFetched(items)) return noItems();
  const byID = formatItems(items);
  return { byID };
};

export const put = (lines, state) => lines.reduce((out, line) => {
  const [itemID, qty] = at(line, ['id', 'qty']);
  return addQty(out, { itemID, qty });
}, state);
