import { createAsyncThunk } from '@reduxjs/toolkit';
import request from '../../../utils/request/index';

export default (type) => createAsyncThunk(
  `orders/fetch/${type}`,
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await request(token).ordersFetch({ type });
      const { orders } = response.data;
      return { orders };
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  },
);
